import { Typography } from "antd";
import { PermissionIntegrationLogo } from "components/Integrations/IntegrationLogo";
import {
  directories,
  resourceIntegrations,
} from "components/Integrations/constants";
import { cloneDeep } from "lodash";
import { useContext } from "react";
import { Permission } from "shared/types/permission";

import { IntegrationsContext } from "../contexts/IntegrationsContext";
import { permissionAccount } from "../requestUtils";

const integrationMap = [
  ...cloneDeep(resourceIntegrations({})),
  ...cloneDeep(directories),
];

export const PermissionLocation: React.FC<{
  permission: Permission;
}> = ({ permission }) => {
  const integrations = useContext(IntegrationsContext);
  const location = permissionAccount(permission, integrations);
  return (
    <>
      {PermissionIntegrationLogo[permission.type]}{" "}
      <Typography.Text code>
        {location
          ? location
          : integrationMap.find((i) => i.key === permission.type)?.label}
      </Typography.Text>
    </>
  );
};
