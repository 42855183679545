export const RiskScores = [
  "BOOST",
  "CRITICAL",
  "EVASION",
  "HIGH",
  "LOW",
  "MEDIUM",
] as const;
export type RiskScore = (typeof RiskScores)[number];

export type Risk = {
  id: string;
  name: string;
  description: string;
  score: RiskScore;
  mitigations: string[];
  links: string[];
};

export type Privilege = {
  id: string;
  name: string;
  scope: "CRITICAL" | "HIGH" | "LOW" | "MEDIUM";
  parent: { description: string; notes: string | null };
  risks: string[];
  notes: string;
  links: string[];
  seeAlso: string[];
  contributedBy?: { name: string; affiliation: string };
};

export const Services = ["aws", "gcp", "k8s", "workspace"] as const;

export type Service = (typeof Services)[number];

export type Catalog = {
  risks: Record<string, Risk>;
  privileges: Record<Service, Record<string, Privilege>>;
};
