import {
  AssessmentNodes,
  GrantEntry,
  IdentityType,
} from "../../../types/assessment/data";

export type GrantCondition = {
  title: string;
  description: string;
  expression: string;
};

export type ServiceAccountKeyData = Pick<
  AssessmentNodes["credential"],
  "createdTime" | "status"
>;

export const grantKey = (e: GrantEntry) =>
  `${e.principalType}:${e.principal}:${e.permissionSet}:${e.resources[0]}:${
    e.condition?.title ?? ""
  }`;

export const fromGrantKey = (key: string) => {
  const [principalType, principal, permissionSet, resource, conditionTitle] =
    key.split(":");
  return {
    principalType,
    principal,
    resource,
    permissionSet,
    condition: conditionTitle ? { conditionTitle } : undefined,
  };
};

export const toIdentityType = (type: string): IdentityType => {
  switch (type) {
    case "domain":
      return "domain";
    case "user":
      return "user";
    case "group":
      return "group";
    case "serviceAccount":
      return "service-account";
    case "allAuthenticatedUsers":
      return "logged-in";
    case "allUsers":
      return "public";
    default:
      return "unknown";
  }
};
