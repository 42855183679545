import { TeamOutlined } from "@ant-design/icons";
import { Grid, Spin, Typography } from "antd";
import { useUser } from "components/Login/hook";
import { useParams } from "react-router";
import styled from "styled-components";

const LoggedInUserContainer = styled.div`
  color: rgba(255, 255, 255, 0.65);
  display: block;
  padding: 10px 24px;

  .ant-typography {
    color: rgba(255, 255, 255, 0.65);
    margin: 4px;
  }
`;

const OrgText = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.65);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

  &:hover {
    overflow: visible;
    white-space: normal;
  }
`;

const OrgIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;

export const LoggedInUserNavItem: React.FC = () => {
  const { user, tenant } = useUser();
  const { orgSlug } = useParams();
  const { lg } = Grid.useBreakpoint();

  return lg ? (
    <LoggedInUserContainer>
      <Typography.Paragraph>Welcome,</Typography.Paragraph>
      {tenant.state === "found" && tenant.isSandbox ? (
        <Typography.Paragraph strong>Sandbox User</Typography.Paragraph>
      ) : user ? (
        <Typography.Paragraph strong>
          {user.displayName || user.email}
        </Typography.Paragraph>
      ) : (
        <Spin />
      )}
      {orgSlug && (
        <OrgIconDiv>
          <TeamOutlined style={{ fontSize: "14px", color: "#8c8c8c" }} />
          <OrgText title={orgSlug}>{orgSlug}</OrgText>
        </OrgIconDiv>
      )}
    </LoggedInUserContainer>
  ) : null;
};
