import { clone, min, sortBy } from "lodash";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";

export type CascadeNode = {
  label: ReactNode;
  key: string;
  children: CascadeNode[];
  sortValue: string;
};

type CascadeProps = {
  tree: CascadeNode[];
  columnStyle?: CSSProperties;
  height?: number;
  maxColumns?: number;
};

const ItemDiv = styled.div`
  padding: 3px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:hover {
    background-color: #eee;
  }
`;

const CascadeItem: React.FC<{
  index: number;
  isOnPath: boolean;
  node: CascadeNode;
  path: string[];
  setPath: (path: string[]) => void;
}> = ({ index, isOnPath, node, path, setPath }) => {
  const updatePath = useCallback(() => {
    if (isOnPath) {
      setPath(path.slice(0, index));
    } else {
      const copy = clone(path);
      copy[index] = node.key;
      setPath(copy);
    }
  }, [index, isOnPath, node.key, path, setPath]);
  return (
    <ItemDiv
      key={node.key}
      onClick={node.children.length > 0 ? updatePath : undefined}
      style={isOnPath ? { backgroundColor: "#f2fbff" } : {}}
    >
      {node.label}
      {isOnPath ? "\xa0>" : "\xa0\xa0"}
    </ItemDiv>
  );
};

export const Cascade: React.FC<CascadeProps> = ({
  tree,
  columnStyle,
  height,
  maxColumns,
}) => {
  const [path, setPath] = useState<string[]>([]);

  useEffect(() => setPath([]), [tree]);

  const columns = useMemo(() => {
    const nColumns = min([path.length + 1, maxColumns ?? 5]) ?? 1;
    const output: ReactNode[][] = [];
    let current: CascadeNode[] = tree;
    for (let ix = 0; ix < path.length + 1; ix++) {
      const isDisplay = ix >= path.length + 1 - nColumns;
      const column = [];
      for (const node of sortBy(current, (n) => n.sortValue)) {
        const isOnPath = node.key === path[ix];
        if (isOnPath) current = node.children;
        if (isDisplay) {
          column.push(
            <CascadeItem
              index={ix}
              key={`${ix}-${node.key}`}
              isOnPath={isOnPath}
              node={node}
              path={path}
              setPath={setPath}
            />
          );
        }
      }
      if (isDisplay) output.push(column);
    }
    return output;
  }, [maxColumns, path, tree]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        gap: "10px",
        flexWrap: "nowrap",
      }}
    >
      {columns.map((c, ix) => (
        <div
          style={{
            ...columnStyle,
            flexBasis: `${(100 / columns.length).toFixed(0)}%`,
            height,
            overflowY: "auto",
          }}
          key={ix}
        >
          {c}
        </div>
      ))}
    </div>
  );
};
