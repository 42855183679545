import { WarningTwoTone } from "@ant-design/icons";
import { Alert } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useMemo } from "react";

import { useFirestoreCollection } from "../../providers/FirestoreProvider";
import { IntegrationConfig } from "../../shared/integrations/shared";
import { Heading } from "../Heading";
import ContactSupport from "./components/ContactSupport";
import IntegrationTable from "./components/IntegrationTable";
import {
  approvers,
  directories,
  notifiers,
  resourceIntegrations,
  siems,
  trackers,
} from "./constants";
import "./styles.less";

export const IntegrationsList: React.FC<object> = () => {
  const flags = useFlags();

  const numberOfResourcesSupported = flags.cloudResourcesSupported;

  const installationConfigs = useFirestoreCollection<IntegrationConfig>(
    "integrations",
    { live: true, tenantAware: true }
  );
  const installations = useMemo(
    () =>
      Object.fromEntries(
        (installationConfigs ?? []).map((c) => [c.id, c.data])
      ),
    [installationConfigs]
  );

  return (
    <>
      <Heading title="Integrations" />
      {numberOfResourcesSupported === 1 && (
        <Alert
          message={
            <>
              Your current plan only allows for the support of a single
              resource. To upgrade and enable support for multiple resources,
              please
              <ContactSupport />.
            </>
          }
          type="warning"
          icon={<WarningTwoTone twoToneColor="#faad14" />}
          showIcon
        />
      )}
      <IntegrationTable
        title="Notifiers"
        data={notifiers(flags)}
        installations={installations}
        zeroNotes={
          <>
            <WarningTwoTone />
            &nbsp;You must install at least one notifier to use P0 Workflows.
          </>
        }
      />
      {flags.assessmentFindingAssignment && (
        <IntegrationTable
          title="Trackers"
          data={trackers}
          installations={installations}
        />
      )}
      {flags.splunkHttpEventCollector && (
        <IntegrationTable
          title="Security Information & Event Managers"
          data={siems}
          installations={installations}
        />
      )}
      <IntegrationTable
        title="Approvers"
        data={approvers}
        installations={installations}
      />
      <IntegrationTable
        title="Directories"
        data={directories}
        installations={installations}
      />
      <IntegrationTable
        title="Resources"
        data={resourceIntegrations(flags)}
        installations={installations}
        zeroNotes={
          <div className="additional-details">
            <WarningTwoTone />
            &nbsp;You must install at least one resource to use P0.
          </div>
        }
      />
    </>
  );
};
