import { Outlet, Route } from "react-router";

import { useOrgInfoStore } from "../../store/org-info/useOrgInfoStore";
import { SubscriptionLevel } from "../../types/entitlement";
import { Page } from "../App/Page";
import { Aws } from "./Aws/Aws";
import { AzureAd } from "./AzureAD/AzureAd";
import { Gcloud } from "./Gcloud/Gcloud";
import { GoogleWorkspace } from "./GoogleWorkspace/GoogleWorkspace";
import { IntegrationsList } from "./Integrations";
import { Jira } from "./Jira/Jira";
import { K8s } from "./Kubernetes/K8s";
import { Okta } from "./Okta/Okta";
import { Pagerduty } from "./Pagerduty/Pagerduty";
import { Pg } from "./Postgres/Pg";
import { Slack } from "./Slack/Slack";
import { Snowflake } from "./Snowflake/Snowflake";
import { Splunk } from "./Splunk/Splunk";
import { Ssh } from "./Ssh/Ssh";
import { Teams } from "./Teams/Teams";

export const IntegrationRoutes = () => {
  const { useSubscriptionPlan } = useOrgInfoStore();
  const subscriptionPlan = useSubscriptionPlan();

  const isRestrictedAccess =
    subscriptionPlan === SubscriptionLevel.EntitlementStarter;

  return (
    <Route path="integrations">
      <Route
        index
        element={
          <Page title="Integrations">
            <IntegrationsList />
          </Page>
        }
      />
      {!isRestrictedAccess && (
        <Route
          path="pagerduty"
          element={
            <Page title="Pagerduty">
              <Pagerduty />
            </Page>
          }
        />
      )}
      {/* Note that we can't guard this behind a flag b/c it has to be accessible prior to login */}
      <Route
        path="jira"
        element={
          <Page title="Jira">
            <Jira />
          </Page>
        }
      />
      <Route
        path="slack"
        element={
          <Page title="Slack">
            <Slack />
          </Page>
        }
      />
      <Route
        path="ms-teams"
        element={
          <Page title="Microsoft Teams">
            <Teams />
          </Page>
        }
      />
      {!isRestrictedAccess && (
        <Route
          path="workspace"
          element={
            <Page title="Google Workspace">
              <GoogleWorkspace />
            </Page>
          }
        />
      )}
      {!isRestrictedAccess && (
        <Route
          path="azure-ad"
          element={
            <Page title="Entra ID">
              <AzureAd />
            </Page>
          }
        />
      )}
      {!isRestrictedAccess && (
        <Route
          path="okta"
          element={
            <Page title="Okta">
              <Okta />
            </Page>
          }
        >
          <Route path=":component" element={<Outlet />}>
            <Route path=":id" element={null} />
          </Route>
        </Route>
      )}
      <Route
        path="aws"
        element={
          <Page title="AWS">
            <Aws />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
      <Route
        path="gcloud"
        element={
          <Page title="Google Cloud">
            <Gcloud />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
      <Route
        path="k8s"
        element={
          <Page title="Kubernetes">
            <K8s />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
      <Route
        path="snowflake"
        element={
          <Page title="Snowflake">
            <Snowflake />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
      <Route
        path="pg"
        element={
          <Page title="PostgreSQL">
            <Pg />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
      <Route
        path="ssh"
        element={
          <Page title="SSH">
            <Ssh />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
      <Route
        path="splunk"
        element={
          <Page title="Splunk">
            <Splunk />
          </Page>
        }
      >
        <Route path=":component" element={<Outlet />}>
          <Route path=":id" element={null} />
        </Route>
      </Route>
    </Route>
  );
};
