import { credentialData } from "./credential";
import { grantData } from "./grant";
import { identityData } from "./identity";
import { resourceData } from "./resource";

export const nodeDataFromShow = {
  grant: grantData,
  credential: credentialData,
  identity: identityData,
  resource: resourceData,
};
