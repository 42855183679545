import { uniqBy, values } from "lodash";
import { keyOf } from "shared/graph/graph";
import { ConnectedNode, NodeOf } from "shared/graph/types";

export type Collapsed<G extends object> = {
  _collapsed: {
    nodes: NodeOf<G & Collapsed<G>>[];
  };
};
export type CollapsedNode<G extends object> = ConnectedNode<
  G & Collapsed<G>,
  keyof (G & Collapsed<G>)
>;
export type GraphEdge<N> = readonly [N, N];
export type CollapsibleNode<G extends object> = {
  key: string;
  type: "_collapsed";
  data: {
    nodes: ConnectedNode<G, keyof G>[];
  };
};
const isCollapsibleNode = <G extends object>(
  node: CollapsibleNode<G> | ConnectedNode<G, keyof G>
): node is CollapsibleNode<G> => node.type === "_collapsed";

const NULL_SENTINEL = "";

/** Collapses groups of nodes with identical singluar parent and child nodes
 *
 * Terminal nodes are also collapsed, as long as the nodes share either one singular
 * parent or one singular child.
 */
export const collapseGraph = <G extends object>(
  edges: Readonly<GraphEdge<ConnectedNode<G, keyof G>>[]>
): Readonly<GraphEdge<CollapsedNode<G>>[]> => {
  const nodes: Record<
    string,
    {
      value: ConnectedNode<G, keyof G>;
      parents: ConnectedNode<G, keyof G>[];
      children: ConnectedNode<G, keyof G>[];
    }
  > = {};
  for (const [parent, child] of edges) {
    const parentKey = keyOf(parent);
    const childKey = keyOf(child);
    nodes[parentKey] ||= { parents: [], children: [], value: parent };
    nodes[parentKey].children.push(child);
    nodes[childKey] ||= { parents: [], children: [], value: child };
    nodes[childKey].parents.push(parent);
  }
  const collapsable: Record<
    string,
    {
      key: string;
      parent: string;
      child: string;
      nodes: ConnectedNode<G, keyof G>[];
    }
  > = {};
  for (const node of values(nodes)) {
    node.parents = uniqBy<ConnectedNode<G, keyof G>>(node.parents, keyOf);
    node.children = uniqBy<ConnectedNode<G, keyof G>>(node.children, keyOf);
    if (node.parents.length <= 1 && node.children.length <= 1) {
      const parent = node.parents.at(0);
      const child = node.children.at(0);
      const parentKey = parent ? keyOf(parent) : NULL_SENTINEL;
      const childKey = child ? keyOf(child) : NULL_SENTINEL;
      // double colon b/c some keys have single colons
      const key = `${String(node.value.type)}::${parentKey}::${childKey}`;
      collapsable[key] ||= {
        key,
        nodes: [],
        parent: parentKey,
        child: childKey,
      };
      collapsable[key].nodes.push(node.value);
    }
  }
  const collapsed: Record<string, CollapsibleNode<G>> = {};
  for (const { key, nodes } of values(collapsable)) {
    if (nodes.length > 1) {
      for (const node of nodes) {
        collapsed[keyOf(node)] = { type: "_collapsed", key, data: { nodes } };
      }
    }
  }
  const collapsedEdges = edges.map(
    ([parent, child]) =>
      [
        collapsed[keyOf(parent)] ?? parent,
        collapsed[keyOf(child)] ?? child,
      ] as any as GraphEdge<
        ConnectedNode<G & Collapsed<G>, keyof (G & Collapsed<G>)>
      >
  );
  return uniqBy(
    collapsedEdges,
    ([parent, child]) =>
      // triple colon b/c keys can already have double colons
      `${isCollapsibleNode(parent) ? parent.key : keyOf(parent)}:::${
        isCollapsibleNode(child) ? child.key : keyOf(child)
      }`
  );
};
