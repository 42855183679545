import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMemo } from "react";
import { GroupAccessIdentity, GroupPrivilege } from "shared/types/directory";
import { widetype } from "shared/util/collections";

type Data = {
  privilege: GroupPrivilege;
  identity?: GroupAccessIdentity;
};

const GROUP_PRIVILEGE_LABELS: Record<GroupPrivilege, string> = {
  add: "Add members",
  approve: "Approve join requests",
  join: "Join",
  view: "View group content",
};

const GROUP_ACCESS_IDENTITY_LABELS: Record<GroupAccessIdentity, string> = {
  admin: "Group administrators",
  domain: "Anyone in your organization",
  group: "Group members",
  invited: "Any invited user",
  owner: "Group owners",
  public: "Anyone on the Internet",
  unknown: "(unknown)",
};

const GROUP_ACCESS_CONTROL_COLUMNS: ColumnType<Data>[] = [
  {
    dataIndex: "privilege",
    title: "Privilege",
    render: (_, { privilege }) => GROUP_PRIVILEGE_LABELS[privilege],
  },
  {
    dataIndex: "identity",
    title: "Identity",
    render: (_, { identity }) =>
      GROUP_ACCESS_IDENTITY_LABELS[identity ?? "unknown"],
  },
];

export const GroupAccessTable: React.FC<{
  access: Partial<Record<GroupPrivilege, GroupAccessIdentity>>;
}> = ({ access }) => {
  const data = useMemo(
    () =>
      widetype.entries(access).map(([privilege, identity]) => ({
        key: privilege,
        privilege,
        identity,
      })),
    [access]
  );
  return (
    <Table
      columns={GROUP_ACCESS_CONTROL_COLUMNS}
      dataSource={data}
      pagination={false}
    />
  );
};
