import { IdentityType } from "shared/types/assessment/data";

export const identityTypeToEmoji: Record<IdentityType, string> = {
  domain: "🌐",
  "logged-in": "🌎",
  user: "👤",
  "iam-group": "👥",
  "iam-user": "👤",
  federated: "🤝",
  group: "👥",
  "service-account": "⚙️",
  public: "🌎",
  unknown: "",
};
