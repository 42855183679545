import { IntegrationResourceRule } from "shared/types/workflow/types";

// We're having to do this backwards as well, due to js-schema-generator not allowing for generics or derived types.
// So we have to use the types as the source of truth instead of these constants.
// And these constants ensure type exhaustiveness of all filter types for every resource.
export const filterTypeOptionsByResource: {
  [K in IntegrationResourceRule["service"]]: Record<
    keyof NonNullable<
      Extract<IntegrationResourceRule, { service: K }>["filters"]
    >,
    string
  >;
} = {
  aws: {
    tag: "Tag",
    group: "Group",
    "permission-set": "Permission Set",
    policy: "Policy",
    resource: "Resource",
    role: "Role",
  },
  "azure-ad": {
    group: "Group",
  },
  gcloud: {
    project: "Project",
    permission: "Permission",
    role: "Role",
    resource: "Resource",
  },
  k8s: {
    role: "Role",
    resource: "Resource",
    cluster: "Cluster",
  },
  okta: {
    group: "Group",
  },
  pg: {
    role: "Role",
  },
  snowflake: {
    role: "Role",
  },
  ssh: {
    // Note:the labels are pluralized in the UI, e.g. Google Cloud instance -> Google Cloud instances
    provider: "Cloud Provider",
    destination: "Destination",
    group: "Group",
    parent: "Parent resource",
    sudo: "Sudo privilege",
    region: "Region",
    all: "All node",
  },
  workspace: {
    group: "Group",
  },
  azure: {
    resource: "Resource",
    role: "Role",
  },
};

const keyOptions = {
  arn: { label: "ARN", value: "arn" },
  id: { label: "ID", value: "id" },
  label: { label: "Label", value: "label" },
  name: { label: "Name", value: "name" },
  type: { label: "Type", value: "type" },
  "full-resource-name": {
    label: "Fully Qualified Resource Name",
    value: "full-resource-name",
  },
};

const sshKeyOptions = {
  arn: { label: "AWS ARN", value: "arn" },
  group: { label: "Group", value: "group" },
  instanceName: { label: "Instance Name", value: "name" },
  groupName: { label: "Group Name", value: "name" },
  parent: {
    label: "GCP Project Name, AWS Account ID, or Azure Subscription ID",
    value: "id",
  },
  "full-resource-name": {
    label: "GCP Fully Qualified Resource Name",
    value: "full-resource-name",
  },
  accountId: { label: "Account ID", value: "id" },
  projectId: { label: "Project ID", value: "id" },
  region: { label: "AWS Region, GCP Zone, or Azure Region", value: "id" },
};

export type ResourceFilterType<K extends IntegrationResourceRule["service"]> =
  keyof Extract<IntegrationResourceRule, { service: K }>["filters"];

export type ResourceFilterKeyOptions<
  K extends IntegrationResourceRule["service"],
> = {
  [P in ResourceFilterType<K>]: FilterKeyOptionsByType;
};

type FilterKeyOptions = Array<{ label: string; value: string }>;
export type FilterKeyOptionsByType =
  | {
      type: "options";
      options: FilterKeyOptions;
    }
  | { type: "boolean" }
  | { type: "input" };

const getOptions = (
  options: FilterKeyOptions
): {
  type: "options";
  options: FilterKeyOptions;
} => ({
  type: "options",
  options,
});

const INPUT_FILTER_KEY = { type: "input" } as const;

const BOOLEAN_FILTER_KEY = { type: "boolean" } as const;

const awsFilterKeyOptions = {
  group: getOptions([keyOptions.name]),
  "permission-set": getOptions([keyOptions.arn, keyOptions.name]),
  policy: getOptions([keyOptions.arn]),
  resource: getOptions([
    keyOptions.name,
    keyOptions.arn,
    { label: "Service", value: "service" },
  ]),
  role: getOptions([keyOptions.name]),
  tag: INPUT_FILTER_KEY,
};

const azureAdFilterKeyOptions = {
  group: getOptions([keyOptions.id, keyOptions.label]),
};

const azureFilterKeyOptions = {
  // TODO: Implement Azure Filter Keys
};

const gcloudFilterKeyOptions = {
  project: getOptions([keyOptions.id]),
  permission: getOptions([keyOptions.id]),
  role: getOptions([keyOptions.id]),
  resource: getOptions([
    keyOptions.name,
    keyOptions.type,
    keyOptions["full-resource-name"],
  ]),
};

const k8sFilterKeyOptions = {
  role: getOptions([keyOptions.name]),
  resource: getOptions([
    { label: "Kind", value: "kind" },
    { label: "Namespace", value: "namespace" },
    keyOptions.name,
  ]),
  cluster: getOptions([keyOptions.name]),
};

const oktaFilterKeyOptions = {
  group: getOptions([keyOptions.id, keyOptions.label]),
};

const pgFilterKeyOptions = {
  role: getOptions([keyOptions.name]),
};

const snowflakeFilterKeyOptions = {
  role: getOptions([keyOptions.name]),
};

const sshFilterKeyOptions = {
  provider: getOptions([keyOptions.id]),
  destination: getOptions([
    // AWS
    sshKeyOptions.arn,
    // GCP
    sshKeyOptions["full-resource-name"],
    // AWS & GCP
    sshKeyOptions.instanceName,
  ]),
  // AWS & GCP
  parent: getOptions([sshKeyOptions.parent]),
  // AWS & GCP
  group: getOptions([sshKeyOptions.groupName]),
  // AWS & GCP
  region: getOptions([sshKeyOptions.region]),
  // AWS & GCP
  sudo: BOOLEAN_FILTER_KEY,
  // AWS & GCP
  all: BOOLEAN_FILTER_KEY,
};

const workspaceFilterKeyOptions = {
  group: getOptions([keyOptions.id, keyOptions.label]),
};

export const filterKeyOptionsByResource: {
  [K in IntegrationResourceRule["service"]]: ResourceFilterKeyOptions<K>;
} = {
  aws: awsFilterKeyOptions,
  "azure-ad": azureAdFilterKeyOptions,
  gcloud: gcloudFilterKeyOptions,
  k8s: k8sFilterKeyOptions,
  okta: oktaFilterKeyOptions,
  pg: pgFilterKeyOptions,
  snowflake: snowflakeFilterKeyOptions,
  ssh: sshFilterKeyOptions,
  workspace: workspaceFilterKeyOptions,
  azure: azureFilterKeyOptions,
};
