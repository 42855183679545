import { ExportOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import { riskLink } from "shared/assessment/render";
import styled from "styled-components";

import { Risk } from "../../../../shared/types/catalog";
import { Severity } from "../Severity";

const LinkDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

/** Renders a link to a single risk in the IAM Risk Catalog
 *
 * If the risk is remediated, renders struck through.
 */
export const RiskLink: React.FC<{
  risk: Risk;
}> = ({ risk }) =>
  risk?.name ? (
    <LinkDiv>
      {risk?.name}
      &nbsp;
      {/* Prevent word wrap of parends */}
      <div>
        (<Severity severity={risk.score.toLocaleLowerCase()} />)
      </div>
      &nbsp;
      <Link href={riskLink(risk)} target="catalog">
        <ExportOutlined />
      </Link>
    </LinkDiv>
  ) : (
    <span>(No known risk)</span>
  );
