import { AssessmentSchemaMap } from "shared/types/assessment/data";

/** Either a type of an AssessmentNode, or "_anything" for untyped queries */
export type Keytype = keyof typeof AssessmentSchemaMap | "_anything";
/** Direct vs. reachable queries, renders as "=" or ":" */
export type Connection = "are" | "reach";
/** Whether the term match should be exact */
export type SearchConstraint = "contain" | "equal";

export type StagedSearch = {
  attribute: string;
  connection: Connection;
  constraint: SearchConstraint;
  exclude: boolean;
  first: boolean;
  invert: boolean;
  keytype: Keytype;
  keyword: string;
};

export const EMPTY_STAGED_SEARCH = {
  attribute: "_key",
  connection: "reach",
  constraint: "contain",
  exclude: false,
  first: false,
  invert: false,
  keyword: "",
  keytype: "_anything",
} as const;

/** Converts a staged node search into a text representation of a node search */
export const buildTerm = (staged: StagedSearch) => {
  const { attribute, first, constraint } = staged;
  const { exclude, invert, keytype, keyword } = staged;
  const textTerm = constraint === "equal" ? `"${keyword}"` : keyword;
  return `${exclude ? "^" : ""}${
    keytype !== "_anything" ? `${keytype}${first ? "=" : ":"}` : ""
  }${attribute !== "_key" ? attribute + ":" : ""}${invert ? "!" : ""}${
    textTerm ?? ""
  }`;
};
