import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { useControls } from "components/Assessment/hooks/useControls";
import { useCallback, useContext, useState } from "react";

import { GraphTooltip } from "../GraphTooltip";
import { TermBuilderForm } from "./form";

/** An interactive term builder for building assessment queries */
export const InteractiveTermBuilder: React.FC<{
  onAddTerm: (term: string) => void;
}> = ({ onAddTerm }) => {
  const { controls } = useControls();
  const { provider } = useContext(ScopeContext);
  const [builderOpen, setBuilderOpen] = useState(false);

  const openBuilder = useCallback(() => setBuilderOpen(true), []);
  const closeBuilder = useCallback(() => setBuilderOpen(false), []);

  const handleSubmit = useCallback(
    (term: string) => {
      closeBuilder();
      onAddTerm(term);
    },
    [closeBuilder, onAddTerm]
  );

  return (
    <>
      <GraphTooltip title="Interactively add a search term">
        <Button
          icon={<PlusOutlined />}
          onClick={openBuilder}
          style={{
            marginLeft: "-1px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </GraphTooltip>
      <Modal
        footer={null}
        maskClosable={false}
        onCancel={closeBuilder}
        open={builderOpen}
        width="600px"
      >
        <TermBuilderForm
          controls={controls}
          provider={provider}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};
