import { uniq } from "lodash";

import { DirectoryKey, DirectoryKeys } from "./directory";
import { ValidationError } from "./error";

export type AssessmentScheduleUnits =
  (typeof AssessmentFrequencyOptions)[number];

export type IamAssessment = {
  frequency?: {
    amount: number;
    unit: AssessmentScheduleUnits;
    anchorDate?: object;
    disabled?: boolean;
  };
  lastAssessmentDate?: object;
  name: string;
  targets: AssessmentScope[];
  subtractiveTargets?: AssessmentScope[];
  assignBy?: AssessmentAssignmentStrategy;
};

// Note that "project" is used for legacy purposes here, but should really be
// "item". TODO: Run a migration to rename.
export const AssessmentScopeTypes = [
  "group",
  "organization",
  "project",
] as const;

export const AssessmentScopeIntegrations = [
  "aws",
  "gcloud",
  "k8s",
  "workspace",
] as const;
export type AssessmentScopeIntegration =
  (typeof AssessmentScopeIntegrations)[number];

export type Provider =
  | DirectoryKey
  | Exclude<AssessmentScopeIntegration, "gcloud">
  | "gcp";
export const Providers: Readonly<Provider[]> = uniq(
  [
    ...DirectoryKeys,
    ...AssessmentScopeIntegrations.filter((p) => p !== "gcloud"),
    "gcp",
  ].sort() as Provider[]
);
export type ProviderOrAll = Provider | "all";
export const providerToScope = (provider: ProviderOrAll) =>
  provider === "gcp" ? "gcloud" : provider;

export const scopeToProvider = (scope: AssessmentScopeIntegration | "all") =>
  scope === "gcloud" ? "gcp" : scope;

const SCOPE_KEY_PATTERN = (() =>
  new RegExp(`(${AssessmentScopeIntegrations.join("|")}):([^:]*)(:.*)?`))();

export const TargetCategories = ["idp", "csp", "service"] as const;
/** Categorizes assessment targets
 *
 * - `idp`: An identity provider; primarily a source of identities
 * - `csp`: A cloud-service provider; hosts identities, grants, and resources
 * - `service`: A standalone or cloud-managed service; may have identities accessed
 *              via grants in a CSP
 */
export type TargetCategory = (typeof TargetCategories)[number];

export type CatalogScopeIntegration =
  | Exclude<AssessmentScopeIntegration, "gcloud">
  | "gcp";

export type AssessmentScopeBase = {
  integration: AssessmentScopeIntegration;
  type: (typeof AssessmentScopeTypes)[number];
};

export type ItemAssessmentScope = AssessmentScopeBase & {
  id: string;
  type: "project";
  label?: string;
  testIndex?: number; // Used for load testing only
  /** If missing, assume installed */
  installState?: "INSTALLED" | "NOT_INSTALLED";
};
export const isItemAssessmentScope = (
  scope: AssessmentScope
): scope is ItemAssessmentScope => scope.type === "project";

export const isGroupAssessmentScope = (
  scope: AssessmentScope
): scope is GroupAssessmentScope => scope.type === "group";

/** Indicates an assessment run on a group of items
 *
 * E.g., in GCP, this would likely be all the projects in a folder,
 * in AWS this would be all accounts with a given tag value
 */
export type GroupAssessmentScope = AssessmentScopeBase & {
  type: "group";
  key: string; // Always 'folder' for GCP
  value: string;
  label?: string;
};

export type OrganizationAssessmentScope = AssessmentScopeBase & {
  type: "organization";
};

export type AssessmentScope =
  | GroupAssessmentScope
  | ItemAssessmentScope
  | OrganizationAssessmentScope;

export const ALL_SCOPE_SENTINEL = "all" as const;

export type ItemScopeOrAll = ItemAssessmentScope | typeof ALL_SCOPE_SENTINEL;

export const toKey = (scope: ItemScopeOrAll) => {
  return scope === ALL_SCOPE_SENTINEL
    ? scope
    : `${scope.integration}:${scope.id}${
        scope.testIndex !== undefined ? `:${scope.testIndex}` : ""
      }`;
};

export const toScope = (key: string): ItemAssessmentScope => {
  const match = key.match(SCOPE_KEY_PATTERN);
  if (!match)
    throw new ValidationError(
      "scope",
      { key },
      `Invalid assessment scope key: '${key}'`
    );
  const [_, integration, id, testIndex] = match;
  return {
    type: "project",
    integration: integration as AssessmentScopeBase["integration"],
    id,
    testIndex:
      testIndex !== undefined ? Number(testIndex.substring(1)) : undefined,
  };
};

export const AssessmentFrequencyOptions = ["days", "months", "weeks"] as const;

export type AssessmentAssignmentStrategy =
  /** Assignee identified by membership in resource parent's contact list */
  | { type: "contact" }
  /** Works in demo only; use VCS to identify finding owner */
  | { type: "iac"; by: "committer" }
  /** The "default" assignment; user assigns manually within their issue tracker */
  | { type: "manual" }
  /** Currently unused; assignee identified by tag or label attached to resource */
  | { type: "tag"; key: string };

// This data is saved in iam-assessments/:assessmentId/access-logs/:scopeKey
// for each assessment and scope
export type AccessLogsInfo = {
  // Exclusive 'until' value of the last aggregation query run on the table
  lastAggregatedUntil: number;
  // Minimum aggregated date in the table
  minAggregatedDate: number;
  // Set to 'STARTED' prior to executing an aggregation query and 'COMPLETED' once
  // aggregation is finished
  // Used to determine whether we were able to save the results of the most recent
  // aggregation query.
  // If we enter aggregation and the status is not COMPLETED, the data in lastAggregatedUntil
  // may be stale and we need to fall back to querying the table
  lastAggregationStatus: "COMPLETED" | "STARTED";
};
