import { MenuOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Alert, Segmented, Spin, Typography } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { DiscoverVisualization } from "components/GraphTable/DiscoverVisualization";
import { Heading } from "components/Heading";
import { SpaceBetweenDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useLocalStorage } from "hooks/useLocalStorage";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { assessmentParse } from "shared/assessment/issues/presets";
import { aggregate } from "shared/graph/aggregate";
import { DiscoverMatch, discoverPaths } from "shared/graph/discover";
import { NodePredicate } from "shared/graph/search";
import {
  DEFAULT_GRAPH_SEARCH_SETTINGS,
  GraphSearchSettings,
} from "shared/graph/settings";
import { ConnectedNode, DirectedGraph, Reducers } from "shared/graph/types";
import {
  AnyAggregates,
  AssessmentNodes,
  TargetNodeTypes,
  isTargetNodeType,
} from "shared/types/assessment/data";
import { isa } from "shared/types/is";
import { staticError } from "utils/console";

import { NodeDisplay } from "../components/node/NodeDisplay";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { useControls } from "../hooks/useControls";
import { NodeLocaleVisualization } from "./AssessmentNodeLocale";
import { GraphProcessingStep } from "./GraphStep";
import { NodeFlowRenderer } from "./GraphVisualization";
import { nodeDataFromShow } from "./node";
import { NodePropertiesDescriber } from "./node/NodeProperties";
import { NodeLabel, NodeText, NodeTitler } from "./node/NodeText";

const NODE_DISPLAY_OPTIONS = [
  { value: "table", label: <MenuOutlined /> },
  { value: "graph", label: <ShareAltOutlined /> },
];

export const ResultDetail: React.FC<{
  terms: string[];
  node: { key: string; type: string };
  mode: "finding" | "query result";
}> = ({ terms, node: { key, type }, mode }) => {
  const nodeData = useMemo(
    () => (isa(TargetNodeTypes, type) ? nodeDataFromShow[type] : undefined),
    [type]
  );
  if (!nodeData)
    return (
      <Alert message="Unexpected node type" description={type} type="error" />
    );

  const { predicate, reducers } = nodeData;
  return (
    <InnerResultDetail
      from={predicate}
      terms={terms}
      node={{ key, type }}
      reducers={reducers as Reducers<AssessmentNodes, AnyAggregates>}
      mode={mode}
    />
  );
};

const InnerResultDetail = <A extends AnyAggregates>({
  from,
  terms,
  node: { key, type },
  reducers,
  mode,
}: {
  from: NodePredicate<AssessmentNodes>;
  terms: string[];
  node: { key: string; type: string };
  reducers: Reducers<AssessmentNodes, A>;
  mode: "finding" | "query result";
}): ReactElement => {
  const { last } = useContext(SelectedAssessmentContext);
  const { loading } = last;
  const { graph, provider, step } = useContext(ScopeContext);
  const [results, setResults] =
    useState<DiscoverMatch<DirectedGraph<AssessmentNodes>>[]>();
  const [settings, _] = useLocalStorage<GraphSearchSettings>(
    "graph-search-settings"
  );
  const { controls, setControls } = useControls();

  const setDisplayMode = useCallback(
    (value: string) => {
      setControls({ ...controls, display: value });
    },
    [controls, setControls]
  );

  const node = useMemo(() => {
    const node = graph?.nodes.find((n) => n.type === type && n.key === key);
    if (!node) return undefined;
    return aggregate({ nodes: [node] }, reducers).nodes[0];
  }, [graph, key, type, reducers]);

  const nodeRenderer = useMemo(
    () =>
      NodeText(node, provider, {
        detailed: false,
        hideThis: true,
      }),
    [provider, node]
  ) as NodeFlowRenderer<AssessmentNodes>;

  const detailTitle = node ? NodeLabel(node, provider) : "Not found";

  useGuardedEffect(
    (cancellation) => async () => {
      if (!node) return undefined;
      // Only ever one node
      const graph = {
        nodes: [node as ConnectedNode<AssessmentNodes, keyof AssessmentNodes>],
      };
      const [discovered] = await discoverPaths(
        graph,
        from,
        assessmentParse(terms.join(" "))
      );
      const results = discovered?.matches;
      if (!results) return cancellation.guard(setResults)([]);
      setResults([{ node: node, matches: results }]);
    },
    [node, from, terms],
    staticError
  );

  const stopOn =
    mode === "finding"
      ? []
      : (settings ?? DEFAULT_GRAPH_SEARCH_SETTINGS).stopOn;

  const showPaths = useMemo(() => !terms.every((s) => s.length === 0), [terms]);

  if (loading) return <Spin />;
  if (step !== "done") return <GraphProcessingStep step={step} />;
  if (!graph || !node || !isTargetNodeType(node))
    return (
      <div>
        <Heading title="Not Found" />
      </div>
    );
  return (
    <div>
      {showPaths && results?.length && (
        <>
          <Typography.Title level={4} style={{ marginTop: "1em" }}>
            {mode === "query result" ? "Query Path" : "Attack Path"}
          </Typography.Title>
          <DiscoverVisualization
            matches={results}
            renderer={NodeText(node, provider)}
            settings={{
              maxResults: 1,
              maxPaths: 100,
              stopOn,
            }}
            titler={NodeTitler(provider)}
            describer={NodePropertiesDescriber(provider)}
            viewStyle={{ height: "500px", width: "1000px" }}
          />
        </>
      )}

      <div style={{ maxWidth: "800px" }}>
        <SpaceBetweenDiv>
          <Typography.Title level={4} style={{ marginTop: "1em" }}>
            This {detailTitle}
          </Typography.Title>

          <Segmented
            options={NODE_DISPLAY_OPTIONS}
            value={controls.display ?? "table"}
            onChange={
              setDisplayMode as Dispatch<SetStateAction<SegmentedValue>>
            }
          />
        </SpaceBetweenDiv>
        {controls.display === "graph" ? (
          nodeRenderer && (
            <NodeLocaleVisualization
              current={node}
              provider={provider}
              renderer={nodeRenderer}
              describer={NodePropertiesDescriber(provider)}
            />
          )
        ) : (
          // Default to table view
          <div style={{ maxWidth: "800px" }}>
            <NodeDisplay node={node} />
          </div>
        )}
      </div>
    </div>
  );
};
