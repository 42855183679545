import { concat } from "lodash";

import { IdentityType } from "../../../types/assessment/data";
import { AwsSpec } from "./accesses";
import { AwsActionsShortcutService, AwsService } from "./types";

// We support services that are indexed by resource explorer
// See https://docs.aws.amazon.com/resource-explorer/latest/userguide/supported-resource-types.html
// Additionally, we also support SageMaker
const RESOURCE_EXPLORER_SERVICES = [
  "cloudfront",
  "cloudwatch",
  "logs",
  "dynamodb",
  "elasticache",
  "ec2",
  "ecs",
  "elasticloadbalancing",
  "iam",
  "kinesis",
  "lambda",
  "es",
  "redshift",
  "rds",
  "resource-explorer-2",
  "servicecatalog",
  "sns",
  "sqs",
  "s3",
  "ssm",
] as const;
const ADDITIONAL_SERVICES = ["eks", "sagemaker", "access-analyzer"] as const;

export const AwsServices = concat(
  RESOURCE_EXPLORER_SERVICES,
  ADDITIONAL_SERVICES
);

export const isAwsService = (service: string): service is AwsService => {
  return AwsServices.includes(service as any);
};

export const AwsActionsShortcutServices = [
  "ec2",
  "eks",
  "s3",
  "sagemaker",
  "ssm",
] as const;

export const isAwsActionsShortcutService = (
  service: string
): service is AwsActionsShortcutService => {
  return AwsActionsShortcutServices.includes(service as any);
};

export const FRIENDLY_ACCESS_TYPES: Record<AwsSpec["access"], string> = {
  role: "role",
  policy: "policy",
  group: "group",
  "permission-set": "permission set",
  resource: "resource",
};

export const P0_TAG_NAME = "P0Security";
export const MANAGED_BY_P0 = "Managed by P0";
export const CREATED_BY_CLOUD_SHELL = "Created using CloudShell";
export const MANAGED_BY_TERRAFORM = "Managed by Terraform";

export const INLINE_ROLE_POLICY_CHAR_SIZE_LIMIT = 6144;
export const MANAGED_POLICY_CHAR_SIZE_LIMIT = 6144;
export const START_SSH_SESSION_DOCUMENT_NAME = "AWS-StartSSHSession";

/** RunCommand document that can
 *  - create a user
 *  - add or remove users to the sudoers file,
 *  - add or remove public keys from a users authorized_keys file
 */
export const PROVISION_USER_ACCESS_DOCUMENT = {
  documentName: "P0ProvisionUserAccess",
  documentVersion: "$DEFAULT", // Use the default version as it is managed by us
};

export const ENV_TO_P0_AWS_SUFFIX = {
  dev: "Dev",
  test: "Dev",
  prod: "",
  stage: "Stage",
};

export const AWS_ACCOUNT_PATTERN = /^\d{12}$/;

/** use us-west-2 as default region, as IAM is global and any region should work. */
export const AWS_ROLE_URL = (context: {
  idcId: string;
  accountId: string;
  roleName: string;
}) =>
  `https://${context.idcId}.awsapps.com/start/#/console?account_id=${
    context.accountId
  }&destination=${encodeURIComponent(
    `https://us-west-2.console.aws.amazon.com/iam/home?region=us-west-2#/roles/details/${context.roleName}?section=permissions`
  )}`;

// TODO: change to switch the instance more principal types are added
export const awsUrlFor = (principalType: IdentityType) =>
  principalType === "service-account" ? AWS_ROLE_URL : undefined;
