import { Page } from "components/App/Page";
import { AppRoutes } from "components/App/routeConstants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Outlet, Route } from "react-router";

import { IntegrationsProvider } from "./contexts/IntegrationsContext";
import { ActiveRequests } from "./pages/ActiveRequestsPage";
import { RequestDetail } from "./pages/RequestDetailPage";
import { RequestHistory } from "./pages/RequestHistoryPage";
import { TabbedActivity } from "./pages/TabbedActivityPage";

export const RequestRoutes = () => {
  const flags = useFlags();
  return (
    <>
      <Route path={AppRoutes.Requests} element={<Outlet />}>
        {flags.showEvidenceStore ? (
          <Route
            index
            element={
              <Page title={"Activity"}>
                <IntegrationsProvider>
                  <TabbedActivity />
                </IntegrationsProvider>
              </Page>
            }
          />
        ) : (
          <Route
            index
            element={
              <Page title={"Requests"}>
                <IntegrationsProvider>
                  <ActiveRequests />
                </IntegrationsProvider>
              </Page>
            }
          />
        )}
        <Route
          path=":requestId"
          element={
            <Page title={"Request Detail"}>
              <RequestDetail />
            </Page>
          }
        />
      </Route>
      <Route
        path={AppRoutes.RequestHistory}
        element={
          <Page title={"Request History"}>
            <IntegrationsProvider>
              <RequestHistory />
            </IntegrationsProvider>
          </Page>
        }
      />
    </>
  );
};
