import { ExportOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import { privilegeLink } from "shared/assessment/render";
import { Provider } from "shared/types/assessment";

/** Renders a link to the IAM Risk Catalog for a permission
 *
 * If the permission is remediated by currently selected recommendations,
 * will render struck-through.
 */
export const PermissionLink = ({
  permission,
  provider,
}: {
  permission: string;
  provider: Provider;
}) => {
  const ref = privilegeLink(provider, permission);
  return (
    <>
      {permission}&nbsp;
      <Link href={ref} target="catalog">
        <ExportOutlined />
      </Link>
    </>
  );
};
