import { Button, Input, Select, Typography } from "antd";
import {
  FilterKeyOptionsByType,
  ResourceFilterType,
  filterTypeOptionsByResource,
} from "components/Jit/Routing/constants";
import { RoutingRuleEditorContext } from "components/Jit/Routing/store/RoutingRuleEditorContext";
import { StyledResourceFilterEdit } from "components/Jit/Routing/styles";
import {
  generateFilterKeyOptions,
  isValidResourceFilterType,
} from "components/Jit/Routing/utils";
import { findIndex } from "lodash";
import pluralize from "pluralize";
import { useCallback, useContext, useMemo } from "react";
import { Filter, IntegrationResourceRule } from "shared/types/workflow/types";
import { keyLabelObjectToOptions } from "utils/antd-utilities";

import { LocalResourceFilterContext } from "./ResourceEditor";

type Props<I extends IntegrationResourceRule> = {
  filterType?: string;
  resource: I;
};

const filterEffectOptions = [
  { label: "Include only", value: "keep" },
  { label: "Exclude only", value: "remove" },
  { label: "Exclude all", value: "removeAll" },
];

export const ResourceFilterEditor = <I extends IntegrationResourceRule>({
  filterType,
  resource,
}: Props<I>): React.ReactElement => {
  const { localFilterType } = useContext(LocalResourceFilterContext);

  const filterKeyOptions = useMemo(() => {
    if (!localFilterType || !resource.service) {
      return undefined;
    }

    return generateFilterKeyOptions(resource.service, localFilterType);
  }, [localFilterType, resource]);

  const availableFilterTypes = useMemo(() => {
    const used = resource.filters ? Object.keys(resource.filters) : [];
    const output = filterType
      ? [
          {
            // We have to do a little TS gymnastics here to get the type to be correct.
            // The unions here end up not having any intersection, so they end up being "never".
            // Therefore, we have to cast it back to string after the fact.
            label: filterTypeOptionsByResource[resource.service][
              filterType as ResourceFilterType<typeof resource.service>
            ] as string,
            value: filterType,
          },
        ]
      : [];

    output.push(
      ...keyLabelObjectToOptions(
        filterTypeOptionsByResource[resource.service]
      ).filter(({ value }) => !used.includes(value))
    );
    return output.map(({ label, value }) => ({
      label: pluralize(label),
      value,
    }));
  }, [resource, filterType]);

  return (
    <ResourceFilterEditorForm
      filterKeyOptions={filterKeyOptions}
      availableFilterTypes={availableFilterTypes}
      filterType={filterType}
    />
  );
};

export const ResourceFilterEditorForm: React.FC<{
  filterKeyOptions: FilterKeyOptionsByType | undefined;
  availableFilterTypes: { label: string; value: string }[];
  filterType?: string;
}> = ({ filterKeyOptions, availableFilterTypes, filterType }) => {
  const {
    localFilterEffect,
    localFilterKey,
    localFilterPattern,
    localFilterType,
    localFilterValue,
    setLocalFilterEffect,
    setLocalFilterKey,
    setLocalFilterPattern,
    setLocalFilterType,
    setLocalFilterValue,
    isDirty,
    onSave,
  } = useContext(LocalResourceFilterContext);
  const { resource, addOrSetResourceFilter } = useContext(
    RoutingRuleEditorContext
  );

  const handleFilterEffectChange = useCallback(
    (effect: Filter["effect"]) => {
      setLocalFilterEffect(effect);
      if (effect === "removeAll") {
        setLocalFilterPattern("");
        setLocalFilterKey("");
        setLocalFilterValue(undefined);
      }
    },
    [
      setLocalFilterEffect,
      setLocalFilterPattern,
      setLocalFilterKey,
      setLocalFilterValue,
    ]
  );

  // The type gets clobbered here by AntD's Select component for some reason.
  // It doesn't seem to like returning I["filters"], so I'm manually confirming it instead.
  // I don't like it either...
  const handleFilterTypeChange = useCallback(
    (type: string) => {
      if (findIndex(availableFilterTypes, { value: type }) !== -1) {
        setLocalFilterType(type);
      }
    },
    [availableFilterTypes, setLocalFilterType]
  );

  const submit = useCallback(() => {
    if (
      !localFilterType ||
      !localFilterEffect ||
      !resource ||
      resource.type !== "integration" ||
      !isValidResourceFilterType(localFilterType, resource.service)
    ) {
      return;
    }
    if (localFilterEffect !== "removeAll") {
      if (filterKeyOptions?.type === "boolean" && localFilterValue == null) {
        return;
      }

      if (
        filterKeyOptions?.type !== "boolean" &&
        (!localFilterKey || !localFilterPattern)
      ) {
        return;
      }
    }
    const newFilter: Filter =
      localFilterEffect === "removeAll"
        ? { effect: localFilterEffect }
        : filterKeyOptions?.type === "boolean"
        ? {
            effect: localFilterEffect,
            value: localFilterValue as boolean,
          }
        : {
            effect: localFilterEffect,
            key: localFilterKey as string, // This is validated in the if statement above but TS isn't smart enough to know that apparently?
            pattern: localFilterPattern as string, // This is validated in the if statement above but TS isn't smart enough to know that apparently?
          };

    // Unfortunately we can't set an appropriate type for the "addOrSetResourceFilter" function
    // because it's based on the resource service which is in runtime.
    // For instance, the following do not work:
    // addOrSetResourceFilter<typeof resource>(localFilterType, newFilter);
    // addOrSetResourceFilter<Extract<IntegrationResourceRule, { service: typeof resource.service }>>(localFilterType, newFilter);
    addOrSetResourceFilter<any>(localFilterType, newFilter, filterType);
    onSave();
  }, [
    localFilterKey,
    localFilterPattern,
    localFilterType,
    localFilterEffect,
    localFilterValue,
    resource,
    filterKeyOptions?.type,
    addOrSetResourceFilter,
    filterType,
    onSave,
  ]);

  const changeFilterType = useCallback(
    (value: string) => handleFilterTypeChange(value),
    [handleFilterTypeChange]
  );

  return (
    <>
      <StyledResourceFilterEdit>
        <Select
          options={filterEffectOptions}
          value={localFilterEffect}
          onSelect={handleFilterEffectChange}
          className="filter-effect"
        />
        <Select
          options={availableFilterTypes}
          value={localFilterType}
          onSelect={changeFilterType}
          className="filter-type"
        />
        <FilterKeyOptionInput
          filterKey={localFilterKey}
          filterPattern={localFilterPattern}
          filterValue={localFilterValue}
          effect={localFilterEffect}
          options={filterKeyOptions}
          onChangeKey={setLocalFilterKey}
          onChangePattern={setLocalFilterPattern}
          onChangeValue={setLocalFilterValue}
        />
      </StyledResourceFilterEdit>
      <Button type="primary" onClick={submit} disabled={!isDirty}>
        Submit
      </Button>
    </>
  );
};

const FilterKeyOptionInput: React.FC<{
  filterKey: string | undefined;
  filterPattern: string | undefined;
  filterValue: boolean | undefined;
  effect: string | undefined;
  options: FilterKeyOptionsByType | undefined;
  onChangeKey: (value: string) => void;
  onChangePattern: (value: string) => void;
  onChangeValue: (value: boolean) => void;
}> = ({
  filterKey,
  filterPattern,
  filterValue,
  effect,
  options,
  onChangeKey,
  onChangePattern,
  onChangeValue,
}) => {
  const changePattern = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChangePattern(e.target.value),
    [onChangePattern]
  );

  if (!effect) {
    return null;
  }

  if (!options) {
    return null;
  }

  if (effect === "removeAll") {
    return null;
  }

  if (options.type === "boolean") {
    return (
      <div className="filter-key-and-pattern">
        <div className="filter-part">
          <Typography.Text>Matches:</Typography.Text>
          <Select
            options={[
              {
                label: "True",
                value: true,
              },
              {
                label: "False",
                value: false,
              },
            ]}
            value={filterValue}
            onSelect={onChangeValue}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="filter-key-and-pattern">
      <div className="filter-part">
        <Typography.Text>With:</Typography.Text>
        <FilterKeyOptions
          options={options}
          value={filterKey}
          onChange={onChangeKey}
        />
      </div>
      <div className="filter-part">
        <Typography.Text>Matching pattern:</Typography.Text>
        <Input
          value={filterPattern}
          onChange={changePattern}
          style={{ marginBottom: 0 }}
        />
      </div>
    </div>
  );
};

const FilterKeyOptions: React.FC<{
  value: string | undefined;
  options: FilterKeyOptionsByType;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  const changeFilterKey = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange]
  );
  if (options.type !== "options") {
    return <Input value={value} onChange={changeFilterKey} />;
  }
  return <Select options={options.options} value={value} onSelect={onChange} />;
};
