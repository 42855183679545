import { TenantAwareLink } from "components/common/TenantAwareLink";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "providers/FirestoreProvider";
import { OktaIntegration } from "shared/integrations/directories/okta/types";

import { AwsLoginInstaller } from "../../../types";
import { oktaProviderInstaller } from "./okta";

export type AwsFederatedLoginInstaller = NonNullable<
  AwsLoginInstaller["options"]["federated"]
>;

export const federatedLoginInstaller: AwsFederatedLoginInstaller = {
  // Move to Okta option if we add additional providers in the future
  prerequisiteMessages: async ({ tenantId }) => {
    const okta = await getDoc(doc(DB, `o/${tenantId}/integrations/okta`));
    if (
      !Object.values(
        (okta?.data() as OktaIntegration | undefined)?.listing ?? {}
      ).find((i) => i.state === "installed")
    )
      return (
        <div>
          Requires installed{" "}
          <TenantAwareLink to="integrations/okta/listing">
            Okta directory listing component
          </TenantAwareLink>
        </div>
      );
  },
  items: {
    provider: {
      options: {
        okta: oktaProviderInstaller,
      },
    },
  },
};
