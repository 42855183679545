import { capitalize } from "lodash";
import pluralize from "pluralize";

import { assertNever } from "../types";
import { ProviderOrAll } from "../types/assessment";
import {
  IdentityType,
  MetaTargetNodeTypes,
  TargetNodeTypes,
} from "../types/assessment/data";
import { Finding } from "../types/assessment/finding";
import { MonitorPriority } from "../types/assessment/monitor";
import { AppContext, appHost } from "../types/environment";

export const DEFAULT_AUTHN_LOOKBACK_DAYS = 40;

export const ACCESS_LOG_LOOKBACK_DAYS = 90;

export const ASSESSMENT_TARGET_REGEX = /^[\w\-_.\s]+$/;

export const AWS_RESOURCE_ARN = /arn:.*?:([^:]+):.*?:.*?:((.+?)[/:])?(.*)/;

export const providerTitles = {
  all: {
    permissionSet: "Privilege set",
    permissions: "Privileges",
  },
  aws: {
    permissionSet: "Policy statement",
    permissions: "Actions",
  },
  "azure-ad": {
    permissionSet: "Role",
    permissions: "Permissions",
  },
  gcp: {
    permissionSet: "Role",
    permissions: "Permissions",
  },
  k8s: {
    permissionSet: "Role",
    permissions: "Verbs",
  },
  okta: {
    permissionSet: "Administrator Role",
    permissions: "Permissions",
  },
  workspace: {
    permissionSet: "Role",
    permissions: "Privileges",
  },
};

export type IamOption = { value: string; label: string };
export const iamShowOptions = (mode: "all" | "item"): IamOption[] => {
  const target =
    mode === "item"
      ? TargetNodeTypes
      : mode === "all"
      ? MetaTargetNodeTypes
      : assertNever(mode);
  return target.map((t) => ({
    value: t,
    label: capitalize(pluralize(t)),
  }));
};

export const identityTypeToLabel = (
  provider: ProviderOrAll
): Record<IdentityType, string> => ({
  domain: "Everyone in a domain",
  federated: "Federated identity",
  group: "Directory group",
  "iam-group": "AWS IAM Group",
  "iam-user": "AWS IAM User",
  "logged-in": "Every authenticated user on the Internet",
  public: "Everyone on the Internet",
  "service-account": provider === "aws" ? "AWS IAM Role" : "Service account",
  unknown: "(unknown principal type)",
  user: "Directory user",
});

export const toLink = (
  { environment }: AppContext,
  orgSlug: string,
  finding: Finding
) =>
  `${appHost(environment)}/o/${orgSlug}/iam-assessments/${String(
    finding.assessmentId
  )}/monitors/${encodeURIComponent(finding.monitorId)}/findings/${String(
    finding.findingId
  )}?scope=${encodeURIComponent(finding.scopeKey)}`;

export const MonitorRanking: Record<MonitorPriority, number> = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
};
export const priorityLabels: Record<MonitorPriority, string> = {
  CRITICAL: "Urgent",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};
