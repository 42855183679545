import { create } from "zustand";

type NewAccountInfo = {
  email: string;
  userDisplayName: string;
  ssoProvider: string | undefined;
};

type CreateAccountPageState = {
  isSubmittingInfo: boolean;
  isGoogleSSOLoading: boolean;
  newAccountInfo: NewAccountInfo;
  showOrgInfo: boolean;
  setIsSubmittingInfo: (value: boolean) => void;
  setIsGoogleSSOLoading: (value: boolean) => void;
  setNewAccountInfo: (value: Partial<NewAccountInfo>) => void;
  setShowOrgInfo: (value: boolean) => void;
};

export const useCreateAccountPageStore = create<CreateAccountPageState>(
  (set) => ({
    isSubmittingInfo: false,
    isGoogleSSOLoading: false,
    showOrgInfo: false,
    newAccountInfo: {
      email: "",
      userDisplayName: "",
      ssoProvider: undefined,
    },

    setNewAccountInfo: (value) => {
      set((state) => ({
        newAccountInfo: { ...state.newAccountInfo, ...value },
      }));
    },

    setShowOrgInfo: (value) => set({ showOrgInfo: value }),
    setIsSubmittingInfo: (value) => set({ isSubmittingInfo: value }),
    setIsGoogleSSOLoading: (value) => set({ isGoogleSSOLoading: value }),
  })
);
