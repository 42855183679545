import { Space, Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { useContext, useMemo } from "react";
import { Node, isNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data/base";
import { assertNever } from "utils/assert";

import {
  ResourceInfo,
  awsResourceInfo,
  gcpResourceInfo,
  k8sResourceInfo,
  resourceLabels,
  workspaceResourceInfo,
} from "../node/resource/labels";
import { HasAddTerm, ShowHideTerm } from "./ShowHide";

export const Resource: React.FC<
  Partial<HasAddTerm> & {
    resource:
      | Node<AssessmentNodes, "grant">
      | Node<AssessmentNodes, "resource">
      | string;
    shortPath?: boolean;
    index?: number;
  }
> = (props) => {
  const { resource, shortPath, terms, onAddTerm, index } = props;
  const { provider } = useContext(ScopeContext);

  const info = useMemo(() => {
    if (typeof resource === "string") {
      return provider === "aws"
        ? awsResourceInfo(resource, shortPath)
        : provider === "gcp"
        ? gcpResourceInfo(resource, shortPath)
        : provider === "k8s"
        ? k8sResourceInfo(resource, shortPath)
        : provider === "workspace"
        ? workspaceResourceInfo(resource, shortPath)
        : ({} as ResourceInfo);
    } else if (isNode("grant")(resource)) {
      if (index === undefined) {
        throw new Error("Index must be provided for 'grant' resources.");
      }
      return resource.data.provider === "aws"
        ? awsResourceInfo(resource.data.resources[index], shortPath)
        : resource.data.provider === "gcp"
        ? gcpResourceInfo(resource.data.resources[index], shortPath)
        : resource.data.provider === "k8s"
        ? k8sResourceInfo(resource.data.resources[index], shortPath)
        : resource.data.provider === "workspace"
        ? workspaceResourceInfo(resource.data.resources[index], shortPath)
        : ({} as ResourceInfo);
    } else if (isNode("resource")(resource)) {
      return resourceLabels(resource.data, shortPath);
    } else {
      return assertNever(resource);
    }
  }, [provider, resource, shortPath, index]);

  const icon = useMemo(
    () => info?.url && <img src={info.url} width={14} height={14} />,
    [info?.url]
  );
  return info ? (
    <GraphTooltip
      title={
        <Space direction="vertical">
          <div>
            {icon}&nbsp;{info.service}
            {info.type ? `\xa0${info.type}` : ""}
          </div>
          <div>{info.isService ? "Entire service" : info.id}</div>
          <div>
            <Typography.Text code>{info.locator}</Typography.Text>
          </div>
          {onAddTerm && (
            <ShowHideTerm
              term={`${info.isGeneric ? "grant=resource:" : "resource="}"${
                info.locator
              }"`}
              name="resources"
              onAddTerm={onAddTerm}
              terms={terms}
            />
          )}
        </Space>
      }
      width="400px"
    >
      {icon}&nbsp;{info.id}
    </GraphTooltip>
  ) : null;
};
