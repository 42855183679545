import * as bfj from "bfj";
import { mapValues, omit } from "lodash";
import { Readable } from "stream";
import { parser } from "stream-json";
import { streamObject } from "stream-json/streamers/StreamObject";

import { keyOf } from "./graph";
import { ConnectedNode, DirectedGraph, Node } from "./types";

type Edge = { parent: string; child: string };
export type Representation<G extends object> = {
  allNodes: Record<string, Node<G, keyof G>>;
  parentNodes: string[];
  edges: Edge[];
};

/** Produces a JSON-friendly (non-recursive) graph representation */
export const toRepresentation = <G extends object>(
  graph: DirectedGraph<G>
): Representation<G> => {
  const allNodes: Record<string, Node<G, keyof G>> = {};
  const edges: Edge[] = [];
  const addNodes = (nodes: ConnectedNode<G, keyof G>[]) => {
    for (const n of nodes) {
      const key = keyOf(n);
      if (!allNodes[key]) {
        allNodes[key] = omit(n, "children", "parents");
        addNodes(n.children);
        for (const c of n.children) {
          edges.push({ parent: key, child: keyOf(c) });
        }
      }
    }
  };
  addNodes(graph.nodes);
  const parentNodes = graph.nodes.map((n) => keyOf(n));
  return { allNodes, parentNodes, edges };
};

/** Produce a referential graph representation from a JSON-friendly representation */
export const fromRepresentation = <G extends object>(
  data: Representation<G>
) => {
  const connected: Record<string, ConnectedNode<G, keyof G>> = mapValues(
    data.allNodes,
    (n) => ({ ...n, children: [], parents: [] })
  );
  for (const edge of data.edges) {
    connected[edge.parent].children.push(connected[edge.child]);
    connected[edge.child].parents.push(connected[edge.parent]);
  }
  const nodes = data.parentNodes.map((key) => connected[key]);
  return { nodes };
};

/** Serializes a directed graph as JSON */
export const serialize = <G extends object>(
  graph: DirectedGraph<G>,
  indent?: number
): Readable =>
  bfj.streamify(toRepresentation(graph), {
    space: indent,
  });

/** Deserializes a JSON representation of a directed graph */
export const deserialize = <G extends object>(
  stream: Readable
): Promise<DirectedGraph<G>> =>
  new Promise((resolve, reject) => {
    // TODO: may need to stream allNodes, edges, and parentNodes separately
    const pipeline = stream.pipe(parser()).pipe(streamObject());
    const data: any = {};

    pipeline.on("data", (event) => {
      data[event.key] = event.value;
    });

    pipeline.on("end", () => {
      const graph = fromRepresentation(data as Representation<G>);
      resolve(graph);
    });
    pipeline.on("error", (err) => {
      reject(err);
    });
  });
