import { InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Switch, Typography } from "antd";
import { Controls } from "components/Assessment/hooks/useControls";
import { SpaceBetweenDiv, VerticalSpacedDiv } from "components/divs";
import pluralize from "pluralize";
import { ChangeEvent, useCallback, useMemo } from "react";
import { ProviderOrAll } from "shared/types/assessment";
import { AssessmentSchemaItem } from "shared/types/assessment/data";

import { GraphTooltip } from "../GraphTooltip";
import {
  termBuilderOptions as options,
  schemaValues,
  toSchemaItem,
} from "./schema";
import {
  Connection,
  EMPTY_STAGED_SEARCH,
  Keytype,
  SearchConstraint,
  buildTerm,
} from "./term-builder";
import { useStaged } from "./useStaged";

const { Paragraph, Text } = Typography;

/** Form for building query terms */
export const TermBuilderForm: React.FC<{
  // controls and provider are passed as props to ease testing
  controls: Controls;
  provider: ProviderOrAll;
  onSubmit: (term: string) => void;
}> = ({ controls, provider, onSubmit }) => {
  const [staged, setStaged] = useStaged();

  // Type clobbering necessary due to TS compiler being overbearing here
  const schemaItem: AssessmentSchemaItem | undefined = useMemo(
    () => toSchemaItem(staged),
    [staged]
  );

  const validKeytypeOptions = useMemo(
    () =>
      staged.connection === "are"
        ? options.keytype.filter((o) => o.value === controls.show)
        : options.keytype,
    [controls, staged]
  );

  const attributeOptions = useMemo(
    () =>
      staged.keytype === "_anything"
        ? [options.attribute.key]
        : [
            options.attribute.key,
            ...schemaValues[staged.keytype].map(
              options.attribute.fromItem(provider)
            ),
          ],
    [provider, staged.keytype]
  );

  const keytermOptions = useMemo(
    () => options.keyword.fromChoices(schemaItem?.choices),
    [schemaItem]
  );

  // If querying direct relationships, can't query for '_anything';
  // in this case reset the searched type to the first available type
  const handleChangeConnection = useCallback(
    (connection: Connection) =>
      setStaged({
        ...staged,
        connection,
        first:
          connection === "are"
            ? true
            : // If keytype is show, wouldn't want an only match for reachability query
            staged.keytype === controls.show
            ? false
            : staged.first,
        keytype: connection === "are" ? controls.show : staged.keytype,
        attribute: "_key",
        constraint: "contain",
        keyword: "",
      }),
    [controls.show, setStaged, staged]
  );

  // If querying '_anything' then attributes don't apply, so clear attribute
  // selection / update constraint selection if necessary
  const handleChangeType = useCallback(
    (keytype: Keytype) => {
      setStaged({
        ...staged,
        keytype,
        attribute: "_key",
        constraint: "contain",
        keyword: "",
      });
    },
    [setStaged, staged]
  );

  // If querying using a 'with' constraint, then select the first available attribute
  const handleChangeConstraint = useCallback(
    (constraint: SearchConstraint) => {
      setStaged({ ...staged, constraint });
    },
    [setStaged, staged]
  );

  const handleChangeAttribute = useCallback(
    (attribute: string) => {
      const base = { ...staged, attribute };
      const choices = toSchemaItem(base)?.choices;
      setStaged({
        ...base,
        constraint: choices ? "equal" : "contain",
        keyword: options.keyword.fromChoices(choices)?.at(0)?.value ?? "",
      });
    },
    [setStaged, staged]
  );

  const handleChangeKeyword = useCallback(
    (event: ChangeEvent<HTMLInputElement> | string) =>
      setStaged({
        ...staged,
        keyword: typeof event === "string" ? event : event.target.value,
      }),
    [setStaged, staged]
  );

  const toggleExclude = useCallback(
    () => setStaged({ ...staged, exclude: !staged.exclude }),
    [setStaged, staged]
  );

  const toggleInvert = useCallback(
    () => setStaged({ ...staged, invert: !staged.invert }),
    [setStaged, staged]
  );

  const toggleFirst = useCallback(
    () => setStaged({ ...staged, first: !staged.first }),
    [setStaged, staged]
  );

  // Query requires a keyword if any of:
  // - Querying all node types
  // - Querying with an exact match (with or without attribute)
  const requiresKeyword = useMemo(
    () => staged.keytype === "_anything" || staged.constraint === "equal",
    [staged]
  );

  const submitTerm = useCallback(() => {
    const term = buildTerm(staged);
    setStaged(EMPTY_STAGED_SEARCH);
    onSubmit(term);
  }, [staged, setStaged, onSubmit]);

  return (
    <VerticalSpacedDiv>
      <div>
        Show {pluralize(controls.show)} that
        <Select<Connection>
          onChange={handleChangeConnection}
          options={options.connect}
          style={{ marginLeft: "0.5em", width: "12em" }}
          value={staged.connection}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          flexWrap: "wrap",
          gap: "0.5em",
          // Create an indentation for multi-line content
          paddingLeft: "24px",
          width: "fit-content",
        }}
      >
        <Select<Keytype>
          onChange={handleChangeType}
          options={validKeytypeOptions}
          value={staged.keytype}
          disabled={staged.connection === "are"}
          // Enough room for "lateral movement paths"
          style={{ marginLeft: "-24px", width: "14em" }}
        />
        &nbsp;with&nbsp;
        <Select
          onChange={handleChangeAttribute}
          options={attributeOptions}
          value={staged.attribute}
          disabled={staged.keytype === "_anything"}
          style={{ width: "14em" }}
        />
        <Select<SearchConstraint>
          onChange={handleChangeConstraint}
          options={
            schemaItem?.choices
              ? options.constraint.choices
              : options.constraint.all
          }
          disabled={!!schemaItem?.choices}
          value={staged.constraint}
          style={{ width: "8em" }}
        />
        {keytermOptions ? (
          <Select<string>
            onChange={handleChangeKeyword}
            options={keytermOptions}
            value={staged.keyword}
            style={{ width: "18em" }}
            data-testid="keyword-select"
          />
        ) : (
          <Input
            onChange={handleChangeKeyword}
            placeholder={`Enter a search term${
              requiresKeyword ? "" : ", or leave empty to match any value"
            }`}
            style={{ marginBottom: 0, width: "28em" }}
          />
        )}
        <Row style={{ width: "20em" }}>
          <Col span={12}>
            Remove match?&nbsp;
            <GraphTooltip
              title={
                <>
                  <Paragraph>
                    Select to remove any results that match your query.
                  </Paragraph>
                  <Paragraph>
                    For example, removing &quot;group&quot; identity types will
                    hide any identity that belongs to a group, including
                    individual users.
                  </Paragraph>
                </>
              }
            >
              <InfoCircleTwoTone />
            </GraphTooltip>
          </Col>
          <Col span={12}>
            <Switch checked={staged.exclude} onChange={toggleExclude} />
          </Col>
          <Col span={12}>
            <label>
              Invert match?&nbsp;
              <GraphTooltip
                title={
                  <>
                    <Paragraph>
                      Select to return results that do not match your search
                      term.
                    </Paragraph>
                    <Paragraph>
                      For example, inverting &quot;group&quot; identity types
                      will remove group identities, but still return user
                      identities, even if those users belong to groups.
                    </Paragraph>
                  </>
                }
              >
                <InfoCircleTwoTone />
              </GraphTooltip>
            </label>
          </Col>
          <Col span={12}>
            <Switch checked={staged.invert} onChange={toggleInvert} />
          </Col>
          <Col span={12}>
            <label>
              Only first node?&nbsp;
              <GraphTooltip
                title={
                  <>
                    <Paragraph>
                      Select to only return a result if the first type in a
                      relationship chain matches your query.
                    </Paragraph>
                    <Paragraph>
                      For example, searching for only the first identity
                      matching a group name would return only that group. With
                      this option unselected, results would contain all
                      identities that are either members of the group, or of
                      which the group is a member.
                    </Paragraph>
                  </>
                }
              >
                <InfoCircleTwoTone />
              </GraphTooltip>
            </label>
          </Col>
          <Col span={12}>
            <Switch
              checked={staged.first}
              disabled={staged.connection === "are"}
              onChange={toggleFirst}
            />
          </Col>
        </Row>
      </div>
      <SpaceBetweenDiv>
        <Text type="secondary">{buildTerm(staged)}</Text>
        <Button
          type="primary"
          disabled={requiresKeyword && !staged.keyword}
          onClick={submitTerm}
        >
          Add term
        </Button>
      </SpaceBetweenDiv>
    </VerticalSpacedDiv>
  );
};
