import { Drawer } from "antd";
import { startCase } from "lodash";
import { ReactNode, useMemo } from "react";
import { NodeOf } from "shared/graph/types";

export const NodeDrawer = <G extends object>(props: {
  node?: NodeOf<G>;
  onClose: () => void;
  describer: (node: NodeOf<G>) => ReactNode;
}) => {
  const { describer, node, onClose } = props;

  const drawerTitle = useMemo(
    () => (node ? startCase(String(node.type)) : undefined),
    [node]
  );

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      // Poorly documented API that forces inline drawer display
      getContainer={false}
      headerStyle={{ padding: "10px" }}
      mask={false}
      onClose={onClose}
      open={!!node}
      placement="right"
      // Necessary for inline drawer, parent must have `position: "relative"`;
      // see https://4x.ant.design/components/drawer/
      style={{ position: "absolute" }}
      title={drawerTitle}
      width="min(80%, 450px)"
    >
      {!!node ? describer(node) : null}
    </Drawer>
  );
};
