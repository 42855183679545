import { StyledRoutingRuleNamePreview } from "components/Jit/Routing/styles";

type Props = {
  name: string | undefined;
};

export const NamePreview: React.FC<Props> = ({ name }) => {
  return (
    <StyledRoutingRuleNamePreview>
      {!!name && name.trim() ? (
        <span>{name}</span>
      ) : (
        <span style={{ fontStyle: "italic", fontWeight: 400 }}>
          (Unnamed rule)
        </span>
      )}
    </StyledRoutingRuleNamePreview>
  );
};
