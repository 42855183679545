import { ApiOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { PermissionIntegrationLogo } from "components/Integrations/IntegrationLogo";
import {
  directories,
  resourceIntegrations,
} from "components/Integrations/constants";
import {
  RoutingRulePopover,
  StyledRoutingRulePreview,
} from "components/Jit/Routing/styles";
import { TenantAwareLink } from "components/common/TenantAwareLink";
import { useFlags } from "launchdarkly-react-client-sdk";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { ResourceRule } from "shared/types/workflow/types";

import { ResourceFilterCount } from "./ResourceFilterCount";
import { SomeRulePopover } from "./SomeRulePreview";

export type ResourceRulePreview =
  | ResourceRule
  | {
      type: "genericIntegration";
    };

type Props = {
  resource: ResourceRulePreview;
};

export const ResourcePreview: React.FC<Props> = ({ resource }) => {
  const flags = useFlags();

  const integrationMap = useMemo(
    () => [
      ...cloneDeep(resourceIntegrations(flags)),
      ...cloneDeep(directories),
    ],
    [flags]
  );

  const resourceFilters = useMemo(() => {
    if (resource.type !== "integration") {
      return [];
    }

    // Must be after the resource.type check above
    const { filters } = resource;

    if (!filters) {
      return [];
    }

    return Object.keys(filters).map((key) => ({
      filterType: key,
      filter: filters[key as keyof typeof filters],
    }));
  }, [resource]);

  return resource.type === "genericIntegration" ? (
    <StyledRoutingRulePreview>
      <ApiOutlined />
      <span>Cloud Integration</span>
    </StyledRoutingRulePreview>
  ) : resource.type === "any" ? (
    // @TODO: Replace with popover with populated list of resources that has a link to integrations.
    <RoutingRulePopover
      text="Any installed resource"
      icon={<ApiOutlined />}
      popoverContent={
        <TenantAwareLink to="integrations">
          You can view all your installed integrations here.
        </TenantAwareLink>
      }
    />
  ) : resource.type === "some" ? (
    <SomeRulePopover
      integrationText="Integration via another rule"
      icon={<ApiOutlined />}
    />
  ) : (
    <StyledRoutingRulePreview>
      <div className="icon-container">
        {PermissionIntegrationLogo[resource.service]}
      </div>
      <Typography.Paragraph className="label">
        {/* NOTE: SSH integration label is not present in the integrationMap yet cause the install UI hasn't been made yet (and this gets its data from there). */}
        {integrationMap.find((i) => i.key === resource.service)?.label ??
          resource.service}
      </Typography.Paragraph>

      {resourceFilters.length > 0 && (
        <ResourceFilterCount resourceFilters={resourceFilters} />
      )}
    </StyledRoutingRulePreview>
  );
};
