import { Element, InstallSpec, ItemComponent } from "../../../install/types";
import { OKTA_ADMIN_DOMAIN, OKTA_DOMAIN } from "./types";

export const OktaComponents = InstallSpec({
  listing: ItemComponent({
    type: "string", // value is Okta public domain
    label: "Directory listing",
    description:
      "Allows P0 to list users and groups; required for other components",
    maxItems: 1,
    schema: {
      clientId: Element({
        label: "Okta application client ID",
        type: "string",
      }),
      keyId: Element({ label: 'API public key ID ("kid")', type: "generated" }),
      publicKey: Element({
        label: "API public key",
        type: "generated",
      }),
    },
    validator: async (_id, field) => {
      if (!field.match(OKTA_DOMAIN) || field.match(OKTA_ADMIN_DOMAIN))
        return "Expected Okta organization domain (e.g. my-org.okta.com)";
    },
  }),
  "group-assignment": ItemComponent({
    type: "dynamic",
    label: "Group assignment",
    description: "Allows P0 to assign users to groups",
    maxItems: 1,
    schema: {},
  }),
});
