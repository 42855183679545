import { OktaIntegration } from "shared/integrations/directories/okta/types";

import { OKTA_CUSTOM_ROLE, OKTA_RESOURCE_SET } from "./constants";

export const oktaApiScopesTerraform = (
  scopes: string[]
) => `resource "okta_app_oauth_api_scope" "p0_api_integration_scopes" {
  app_id = okta_app_oauth.p0_api_integration.id
  issuer = local.org_domain # Assumes that the application uses the default org domain
  scopes = [
    ${scopes.join(",\n    ")}
  ]
}`;

export const oktaTerraform = {
  listing: (id: string, item: OktaIntegration["listing"][string]) => {
    if (!item.publicKey) return "";
    const jwk = JSON.parse(item.publicKey);
    return `terraform {
  required_providers {
    okta = {
      source  = "okta/okta"
      version = "4.8.0"
    }
  }
}

locals {
  org_domain = "https://${id}"
}

# To import: terraform import "okta_app_oauth.p0_api_integration" {applicationId}
resource "okta_app_oauth" "p0_api_integration" {
  label                      = "P0 API Integration"
  type                       = "service"
  token_endpoint_auth_method = "private_key_jwt"
  pkce_required              = false
  grant_types                = ["client_credentials"]
  response_types             = ["token"]
  issuer_mode                = "DYNAMIC"
  # "Require Demonstrating Proof of Possession (DPoP) header in token requests" must be false.
  # This argument is not support yet by the Terraform provider, however, the resulting application doesn't enable it when created from Terraform. (Created from the UI it defaults to true.)
  # dpop_bound_access_tokens = false

  jwks {
    kty = "${jwk.kty}"
    kid = "${jwk.kid}"
    e   = "${jwk.e}"
    n   = "${jwk.n}"
  }
}

# To import: terraform import "okta_app_oauth_api_scope.p0_api_integration_scopes" {applicationId}
${oktaApiScopesTerraform(["okta.groups.manage", "okta.users.read"])}

# To import: terraform import "okta_admin_role_custom.p0_lister_role" {customRoleId}
resource "okta_admin_role_custom" "p0_lister_role" {
  label       = "${OKTA_CUSTOM_ROLE}"
  description = "Allows P0 Security to read all users and all groups"
  permissions = [
    "okta.users.read",
    "okta.groups.read"
  ]
}

# To import: terraform import "okta_resource_set.p0_all_users_groups" {resourceSetId}
resource "okta_resource_set" "p0_all_users_groups" {
  label       = "${OKTA_RESOURCE_SET}"
  description = "All users and all groups"
  resources = [
    "\${local.org_domain}/api/v1/users",
    "\${local.org_domain}/api/v1/groups"
  ]
}

# To import: terraform import "okta_app_oauth_role_assignment.p0_lister_role_assignment" {clientId}/{roleAssignmentId}
resource "okta_app_oauth_role_assignment" "p0_lister_role_assignment" {
  type         = "CUSTOM"
  client_id    = okta_app_oauth.p0_api_integration.client_id
  role         = okta_admin_role_custom.p0_lister_role.id
  resource_set = okta_resource_set.p0_all_users_groups.id
}`;
  },
  "group-assignment": `# To import: terraform import "okta_app_oauth_role_assignment.p0_group_membership_admin_role_assignment" {clientId}/{roleAssignmentId}
resource "okta_app_oauth_role_assignment" "p0_group_membership_admin_role_assignment" {
  type      = "GROUP_MEMBERSHIP_ADMIN"
  client_id = okta_app_oauth.p0_api_integration.client_id
}`,
};
