import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv } from "components/divs";
import { policyName } from "shared/integrations/resources/aws/util";
import { roleName } from "shared/integrations/resources/gcloud/util";
import { ALL_SCOPE_SENTINEL } from "shared/types/assessment";
import { ProviderOrAll } from "shared/types/assessment";
import { assertNever } from "utils/assert";

import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const awsLabel = (policyStatement: string | undefined) => {
  if (!policyStatement) return null;
  const [policy, statement] = policyStatement.split(":");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {policy}
      {statement && (
        <Typography.Text type="secondary">
          {isNaN(+statement) ? statement : `Statement ${statement}`}
        </Typography.Text>
      )}
    </div>
  );
};

const k8sLabel = (permissionSet: string) => {
  const splitIndex = permissionSet.lastIndexOf(":");
  const roleId = permissionSet.substring(0, splitIndex);
  const ruleIndex = permissionSet.substring(splitIndex + 1);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {roleId}
      <Typography.Text type="secondary">{`Rule ${ruleIndex}`}</Typography.Text>
    </div>
  );
};

export const permissionSetName = (
  provider: ProviderOrAll,
  permissionSet: string | undefined
) =>
  permissionSet
    ? provider === "aws"
      ? awsLabel(policyName(permissionSet))
      : provider === "gcp"
      ? roleName(permissionSet)
      : provider === "k8s"
      ? k8sLabel(permissionSet)
      : provider === "all" ||
        provider === "azure-ad" ||
        provider === "okta" ||
        provider === "workspace"
      ? permissionSet
      : assertNever(provider)
    : undefined;

export const PermissionSet: React.FC<
  {
    permissionSet?: string;
    provider?: ProviderOrAll;
  } & HasAddTerm
> = ({ permissionSet, provider, ...props }) => {
  const name = permissionSetName(provider ?? ALL_SCOPE_SENTINEL, permissionSet);
  return name ? (
    <ClipDiv>
      <GraphTooltip
        width="fit-content"
        title={
          <>
            {name}
            <ShowHideTerm
              term={`role:"${permissionSet}"`}
              name="roles"
              {...props}
            />
          </>
        }
      >
        {name}
      </GraphTooltip>
    </ClipDiv>
  ) : null;
};
