import { QuestionCircleTwoTone } from "@ant-design/icons";
import Typography from "antd/lib/typography";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { providerTitles } from "shared/assessment/constants";

const { Paragraph, Text } = Typography;

export const providerTitleElements = {
  ...providerTitles,
  all: {
    permissionSet: (
      <div>
        Privilege set{" "}
        <GraphTooltip
          title={
            <div>
              <Paragraph>
                This is the set of granted privileges. It has different names
                depending on your service:
              </Paragraph>
              {/* TODO: separate concerns */}
              <dl>
                <dt>AWS</dt>
                <dd>
                  An <Text code>Actions</Text> block within a policy statement
                </dd>
                <dt>Google Cloud</dt>
                <dd>A role</dd>
              </dl>
            </div>
          }
        >
          <QuestionCircleTwoTone />
        </GraphTooltip>
      </div>
    ),
    permissions: "Privileges",
  },
};
