import { message } from "antd";
import yaml from "js-yaml";
import { useCallback } from "react";
import { WorkflowConfig } from "shared/types/workflow/types";

import { useRoutingRulesStore } from "../../../store/routingRulesStore";
import { useAuthFetch } from "../../Login/hook";

export const useSubmitWorkflow = () => {
  const {
    setCurrentVersion,
    setErrors,
    setJsonRules,
    setIsSubmitting,
    setYamlRules,
    setShowConfigRulesModal,
  } = useRoutingRulesStore();

  const setSingleError = useCallback(
    (error: string | undefined) => setErrors(error ? [error] : []),
    [setErrors]
  );

  const authFetch = useAuthFetch(setSingleError);

  return useCallback(
    async ({
      jsonData,
      shouldNavigate = true,
    }: {
      // TODO: move to shared types
      jsonData: {
        currentVersion: string | undefined;
        workflow: WorkflowConfig;
      };
      shouldNavigate: boolean;
    }) => {
      setIsSubmitting(true);
      try {
        const response = await authFetch("routing", {
          method: "POST",
          json: jsonData,
          onNotOk: async (response) => {
            if (response.status === 400) {
              setIsSubmitting(false);
              const data = await response.json();
              setErrors(
                data.errorState.errors?.map((e: any) => `${e.error}`) ?? [
                    data.error,
                  ] ?? [String(data)]
              );
            } else if (response.status > 400) {
              setIsSubmitting(false);
              try {
                const { error } = await response.json();
                setErrors([error]);
              } catch (_) {
                setErrors([
                  "Saving workflow failed. You may try again, or, if this problem persists, please contact support@p0.dev.",
                ]);
              }
            }
          },
        });
        if (!response) return;
        const {
          workflow: { rules, version },
        } = await response.json();
        setCurrentVersion(version);
        setJsonRules(rules);
        setYamlRules(yaml.dump(rules, { flowLevel: 4 }));
        message.success("Workflow successfully saved.");
        setIsSubmitting(false);
        setErrors([]);
        if (shouldNavigate) {
          setShowConfigRulesModal(false);
        }
      } catch (err) {
        setIsSubmitting(false);
        setErrors([String(err)]);
      }
    },
    [
      authFetch,
      setCurrentVersion,
      setErrors,
      setIsSubmitting,
      setJsonRules,
      setShowConfigRulesModal,
      setYamlRules,
    ]
  );
};
