import { Node } from "../graph/types";
import { awsUrlFor } from "../integrations/resources/aws/constants";
import {
  ALL_SCOPE_SENTINEL,
  AssessmentScopeIntegration,
  Provider,
  ProviderOrAll,
  toScope,
} from "../types/assessment";
import {
  AssessmentNodes,
  GrantNode,
  IdentityNode,
  IdentityType,
} from "../types/assessment/data";
import { Monitor } from "../types/assessment/monitor";
import { Risk } from "../types/catalog";
import { identityTypeToLabel } from "./constants";

export type IdentityNodeLabels = {
  identity: string;
  identityType: string;
  type: "identity";
};

export type GrantNodeLabels = {
  identity: string;
  identityType: string;
  permissionSet: string;
  resource: string;
  type: "grant";
};

export type NodeLabels = GrantNodeLabels | IdentityNodeLabels;

export const grantNodeLabels = (
  provider: ProviderOrAll,
  node: GrantNode
): GrantNodeLabels => ({
  identity: node.data.principal,
  identityType: identityTypeToLabel(provider)[node.data.principalType],
  permissionSet: grantNodeToPermissionSet(node),
  resource: node.data.resources.join(", "),
  type: "grant",
});

export const identityNodeLabels = (
  provider: ProviderOrAll,
  { data }: IdentityNode
): NodeLabels => ({
  identity: data.label,
  identityType: identityTypeToLabel(provider)[data.type],
  type: "identity",
});

export const providerLabels: Record<Provider, string> = {
  "azure-ad": "Entra ID",
  okta: "Okta",
  workspace: "Google Workspace",
  aws: "AWS",
  k8s: "Kubernetes",
  gcp: "Google Cloud",
};

export const grantNodeToPermissionSet = (
  node: Node<AssessmentNodes, "grant">
) =>
  // Handles both gcp `projects/1234/roles/foo` and AWS `Policy:Statement`
  node.data.permissionSet.split("/").at(-1)?.split(":")?.[0] ??
  "Unknown permission set";

export const findingTitle = (monitor: Monitor, labels: NodeLabels) =>
  `${monitor.label}: ${labels.identity}`;

export const riskLink = (risk: Pick<Risk, "id" | "name" | "score">) =>
  `https://catalog.p0.dev/risks/${risk.id}`;

export const privilegeLink = (provider: Provider, privilege: string) =>
  `https://catalog.p0.dev/services/${provider}/${encodeURIComponent(
    privilege
  )}`;

export const principalLink = (
  principal: string,
  principalType: IdentityType,
  scopeKey: string,
  integration?: AssessmentScopeIntegration,
  idcId?: string | undefined
): string | undefined =>
  integration === "aws" && scopeKey !== ALL_SCOPE_SENTINEL && idcId
    ? awsUrlFor(principalType)?.({
        idcId: idcId,
        accountId: toScope(scopeKey).id,
        roleName: principal,
      })
    : undefined;
