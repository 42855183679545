import { Typography } from "antd";
import moment from "moment";
import { AssessmentNodes } from "shared/types/assessment/data";

const { Text } = Typography;

export const LastRotated: React.FC<{
  credential: AssessmentNodes["credential"];
}> = ({ credential: { createdTime } }) => (
  <>
    {createdTime ? (
      moment(createdTime).format("LL")
    ) : (
      <Text type="secondary">(Unknown)</Text>
    )}
  </>
);
