import styled from "styled-components";

/** Creates a single "row" in a multi-line cell display.
 *
 * This row clips text with an ellipsis. Use with a {@link GraphTooltip}
 * to expand ellipsis content.
 */
export const CellInner = styled.div`
  overflow-x: clip;
  text-overflow: ellipsis;
`;
