import { List, Segmented } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { VerticalSpacedDiv } from "components/divs";
import { Dictionary, mapValues, omitBy, size, sortBy } from "lodash";
import { useContext, useMemo, useState } from "react";
import { identityTypeToLabel } from "shared/assessment/constants";
import { dfs } from "shared/graph/graph";
import { ConnectedNode, isConnectedNode } from "shared/graph/types";
import {
  AssessmentNodes,
  Identity,
  IdentityNode,
} from "shared/types/assessment/data";

import { identityTypeToEmoji } from "./shared";

const MemberOptions = ["all", "direct", "indirect"] as const;
export type MemberOption = (typeof MemberOptions)[number];

const arrayToRecord = (
  nodes: ConnectedNode<AssessmentNodes, keyof AssessmentNodes>[]
) =>
  Object.fromEntries(
    nodes.filter(isConnectedNode("identity")).map((n) => [n.key, n.data])
  );

const MemberIdentityDisplay = ({ identity }: { identity: Identity }) => {
  const { provider } = useContext(ScopeContext);

  return (
    <>
      {identityTypeToEmoji[identity.type]}
      &nbsp;
      {identity.type === "public"
        ? identityTypeToLabel(provider).public
        : identity.label}
    </>
  );
};

const renderMemberItem = (identity: AssessmentNodes["identity"]) => {
  return (
    <List.Item key={identity.label}>
      <div style={{ display: "flex", gap: "1em" }}>
        <MemberIdentityDisplay
          data-testid={identity.label}
          identity={identity}
        />
      </div>
    </List.Item>
  );
};

export const GroupMembers: React.FC<{ node: IdentityNode }> = ({ node }) => {
  const [membershipType, setMembershipType] = useState<MemberOption>("all");

  const result = useMemo(() => {
    const direct = arrayToRecord(node.parents);
    const traversed = dfs<
      AssessmentNodes,
      Record<string, AssessmentNodes["identity"]>
    >({ nodes: [node] }, "parents", {
      init: (node) => arrayToRecord(node.parents),
      combine: (left, right) => ({ ...left, ...right }),
    });
    const all = traversed[`identity:${node.key}`];
    const indirect = omitBy(all, (_, key) => key in direct);
    return mapValues({ all, direct, indirect }, (r: Dictionary<Identity>) =>
      sortBy(Object.values(r), (i) => i.label)
    );
  }, [node]);

  const options = useMemo(
    () => [
      {
        label: `All (${size(result?.all) ?? 0})`,
        value: "all",
      },
      {
        label: `Direct (${size(result?.direct) ?? 0})`,
        value: "direct",
      },
      {
        label: `Indirect (${size(result?.indirect) ?? 0})`,
        value: "indirect",
      },
    ],
    [result]
  );

  return (
    <VerticalSpacedDiv>
      <div style={{ display: "flex", gap: "1em" }}>
        <Segmented
          options={options}
          value={membershipType}
          onChange={setMembershipType as any}
        />
      </div>
      <List<Identity>
        dataSource={result[membershipType]}
        renderItem={renderMemberItem}
        style={{ maxHeight: "400px", overflowY: "auto" }}
      />
    </VerticalSpacedDiv>
  );
};
