import { Descriptions } from "antd";
import { Typography } from "antd";
import {
  LastAuthenticated,
  authnHelp,
} from "components/Assessment/components/cells/LastAuthenticated";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import { min } from "lodash";
import pluralize from "pluralize";
import { useContext } from "react";
import { DEFAULT_AUTHN_LOOKBACK_DAYS } from "shared/assessment/constants";
import { Node } from "shared/graph/types";
import {
  AssessmentNodes,
  IdentityAggregates,
  IdentityNode,
  IdentityType,
  TrustedPrincipals,
} from "shared/types/assessment/data";

import { ConsumersList } from "../../cells/ConsumersList";
import {
  CredentialList,
  serviceAccountInfoFromKey,
} from "../../cells/Credential";
import { GroupAccessTable } from "../../cells/GroupAccess";
import { IdentityExternalLink, IdentityLink } from "../../cells/Identity";
import { RiskGroupedPermissionList } from "../../cells/PermissionAggregate";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { GroupMembers } from "./GroupMember";
import { credentialsByStaleness, credentialsByUsage } from "./grouping";

const { Text } = Typography;
const { Item } = Descriptions;

const hasMembers = (type: IdentityType) =>
  type === "group" || type === "federated";

export const TrustPolicyDisplay: React.FC<{
  trustedPrincipals: TrustedPrincipals;
}> = ({ trustedPrincipals }) => (
  <div>
    <Typography style={{ fontWeight: 700 }}>Trust Policy:</Typography>
    <CommandDisplay
      hideCopy
      commands={JSON.stringify(
        JSON.parse(trustedPrincipals.trustPolicy),
        null,
        2
      )}
    />
  </div>
);

/* 
We must map each of usedCredentials.used, usedCredentials.unused, staleCredentials.stale, staleCredentials.fresh to
extract only the data part and the additional service account key information. The non-data part (parents, children) may
lead to circular references when exporting to JSON. Also flattens the data so it's easy to answer questions like
"used" and "stale" credentials.
*/
export const credentialExportFromIdentity = (node: IdentityNode) => {
  const credentialMap = new Map<
    string,
    AssessmentNodes["credential"] & { used?: boolean; stale?: boolean }
  >();

  const updateCredential = (
    key: string,
    data: AssessmentNodes["credential"] & {
      used?: boolean;
      stale?: boolean;
    }
  ) => {
    const credential = credentialMap.get(key);
    if (credential) {
      credentialMap.set(key, { ...credential, ...data });
    } else {
      credentialMap.set(key, data);
    }
  };
  const credentialsUsage = credentialsByUsage(
    node.aggregates.credentials ?? []
  );
  const credentialsStaleness = credentialsByStaleness(
    node.aggregates.credentials ?? []
  );

  for (const cred of credentialsUsage.used) {
    updateCredential(cred.id, { used: true, ...cred });
  }
  for (const cred of credentialsUsage.unused) {
    updateCredential(cred.id, { used: false, ...cred });
  }
  for (const cred of credentialsStaleness.stale) {
    updateCredential(cred.id, { stale: true, ...cred });
  }
  for (const cred of credentialsStaleness.fresh) {
    updateCredential(cred.id, { stale: false, ...cred });
  }

  return Array.from(credentialMap.entries()).map(([key, credential]) => {
    return {
      ...serviceAccountInfoFromKey(key),
      ...credential,
    };
  });
};

export const identityLookback = (
  credentials: (AssessmentNodes["credential"] & {
    id: string;
  })[]
) =>
  min(
    credentials.map(
      (c) => c.maxAuthnLookbackDays ?? DEFAULT_AUTHN_LOOKBACK_DAYS
    )
  );

const consumers = (
  node: IdentityNode,
  credentials: IdentityAggregates["credentials"]
) =>
  node.data.type === "service-account"
    ? credentials.find((c) => c.type === "short-lived")
    : undefined;

export const IdentityNodeDisplay: React.FC<{ node: IdentityNode }> = ({
  node,
}) => {
  const { aggregates, data } = node;
  const { provider } = useContext(ScopeContext);
  const grantCount = aggregates.grants.length;
  const credentials = aggregates.credentials;

  const lookback = identityLookback(credentials);
  const impersonationInfo = consumers(node, credentials);
  const impersonationConsumers = aggregates.consumers.filter(
    (consumer) => consumer.type === "principal"
  );

  const { access: groupAccess } = data;

  return (
    <>
      <NodeDescriptions>
        <Item label="Identity">
          <IdentityExternalLink data={data} id={node.key} />
        </Item>
        <Item label="Parent">
          {data.parent ? (
            <Resource
              resource={
                {
                  data: {
                    resources: [data.parent],
                    provider: data.provider,
                  },
                  type: "grant",
                } as Node<AssessmentNodes, "grant">
              }
              index={0}
            />
          ) : (
            <Text type="secondary">(Unknown)</Text>
          )}
        </Item>
        <Item label="Last Used">
          <LastAuthenticated
            identity={data}
            last={aggregates.lastAuthEvent}
            lookback={lookback}
          />
        </Item>
        {impersonationInfo && (
          <Item label="Used By">
            <ConsumersList
              consumers={impersonationConsumers}
              lastAuthnTime={impersonationInfo.lastAuthnTime}
            />
          </Item>
        )}
        {node.data.type === "user" && (
          <Item label="MFA">
            {node.data.mfa || (
              <GraphTooltip title={authnHelp["user"]}>Unknown</GraphTooltip>
            )}
          </Item>
        )}
        {(data.type === "user" ||
          (data.type === "service-account" && !data.isProviderManaged)) && (
          <Item label="Authentication Methods">
            <CredentialList
              identityType={data.type}
              credentials={aggregates.credentials}
            />
          </Item>
        )}
        {hasMembers(data.type) && (
          <Item label="Members">
            <GroupMembers node={node} />
          </Item>
        )}
        <Item label="Grants">
          <Typography.Paragraph>
            This identity can exercise {grantCount}{" "}
            {pluralize("grant", grantCount)}
          </Typography.Paragraph>
          <IdentityLink data={data} show="identity" />
        </Item>
        <Item label="Risks">
          <RiskGroupedPermissionList
            permissions={node.aggregates.permissions}
            provider={provider}
            showControl
          />
        </Item>
        {groupAccess && (
          <Item label="Access">
            <GroupAccessTable access={groupAccess} />
          </Item>
        )}
      </NodeDescriptions>
      {node.data.trustedPrincipals && (
        <TrustPolicyDisplay
          trustedPrincipals={node.data.trustedPrincipals}
        ></TrustPolicyDisplay>
      )}
    </>
  );
};
