import { identity } from "lodash";

import { distinct } from "../../graph/aggregate";
import {
  ConnectedNode,
  Reducer,
  Reducers,
  isConnectedNode,
  isNode,
} from "../../graph/types";
import { AssessmentNodes, Usage, Usages } from "../../types/assessment/data";
import {
  GrantAggregates,
  MetaGrantAggregates,
} from "../../types/assessment/data/grant";
import { keyAggregate, permissionAggregate } from "./shared";

const EMPTY_USAGE = Object.fromEntries(
  Usages.map((u) => [u, 0] as const)
) as Record<Usage, number>;

const usageCounts: Reducer<
  AssessmentNodes,
  Record<Usage, number>,
  Record<Usage, number> | undefined,
  Record<Usage, number>
> = {
  finalize: identity,
  initialize: () => ({ ...EMPTY_USAGE }),
  reduce: (memo, value) => {
    for (const u of Usages) {
      memo[u] = value?.[u] ?? 0;
    }
  },
  span: () => [], // never descend
  toValue: (node) =>
    isNode("grant")
      ? (Object.fromEntries(
          (node as ConnectedNode<AssessmentNodes, "grant">).children
            .filter(isConnectedNode("usage"))
            .map((n) => [n.data.type, n.data.count ?? 0] as const)
        ) as Record<Usage, number>)
      : undefined,
};

export const grantReducers: Reducers<AssessmentNodes, GrantAggregates> = {
  permissions: permissionAggregate,
  principals: keyAggregate("identity"),
  risks: keyAggregate("risk"),
  usages: usageCounts,
};

const lateralByGrant = distinct<
  AssessmentNodes,
  "lateral",
  AssessmentNodes["lateral"]
>("lateral", ({ data }) => data, identity);

export const metaGrantReducers: Reducers<AssessmentNodes, MetaGrantAggregates> =
  {
    laterals: lateralByGrant,
    permissions: permissionAggregate,
  };
