import { Export } from "components/Export";
import { Tenant } from "components/Login";
import { format } from "date-fns";
import { PERMISSION_REQUESTS_COLLECTION } from "firestore/constants";
import {
  FirestoreDoc,
  getFirestoreCollection,
} from "providers/FirestoreProvider";
import { useCallback, useContext } from "react";
import { PermissionRequest } from "shared/types/permission";

import {
  extractApproverName,
  getRequestEvents,
  requestDescription,
  statusDescriptions,
} from "../requestUtils";

const EXPORT_COLUMNS = [
  "id",
  "type",
  "description",
  "requestedBy",
  "approvedBy",
  "requestedTime",
  "approvedTime",
  "grantedTime",
  "expiryTime",
  "status",
  "status description",
  "reason",
];

const DETAILED_EXPORT_COLUMNS = [
  "id",
  "type",
  "description",
  "requestedBy",
  "approvedBy",
  "lastUpdatedTime",
  "status",
  "status description",
  "reason",
];

const createRow = (result: string, data: PermissionRequest, id: string) => {
  result += `${id}\t`;
  result += `${data.type}\t`;
  result += `"${requestDescription(data)}"\t`;
  const timestamp =
    data.approvalDetails?.approvedTimestamp ??
    // For legacy pre-approvalDetails requests
    (data.notifications?.slack as any)?.approvedTimestamp;
  result += [
    data.principal,
    extractApproverName(data, false),
    formatDateTimeForCsv(data.requestedTimestamp),
    timestamp ? formatDateTimeForCsv(timestamp) : "",
    data.grantTimestamp ? formatDateTimeForCsv(data.grantTimestamp) : "",
    data.expiryTimestamp ? formatDateTimeForCsv(data.expiryTimestamp) : "",
    data.status,
    `"${statusDescriptions[data.status](data)}"`,
    data.reason ?? "",
  ].join("\t");
  return result;
};

const formatDateTimeForCsv = (timestamp: number) => {
  const dateBase = new Date(timestamp);
  return format(dateBase, "yyyy-MM-dd HH:mm:ss");
};

const toTsv = async (
  requests: FirestoreDoc<PermissionRequest>[]
): Promise<string> => {
  let result = EXPORT_COLUMNS.join("\t") + "\n";

  for (const { id, data } of requests) {
    result = createRow(result, data, id);
    result += "\n";
  }
  return result;
};

const toDetailedTsv =
  (tenantId: string) =>
  async (requests: FirestoreDoc<PermissionRequest>[]): Promise<string> => {
    let result = DETAILED_EXPORT_COLUMNS.join("\t") + "\n";
    const promises = [];
    for (const { id, data } of requests) {
      promises.push(
        (async () => {
          const logs = await getFirestoreCollection<PermissionRequest>(
            `o/${tenantId}/${PERMISSION_REQUESTS_COLLECTION}/${id}/logs`
          );

          const { events } = getRequestEvents(data, logs ?? []);
          if (logs.length === 0) {
            result = createRow(result, data, id);
            result += "\n";
          } else
            for (const { updates: logData, initialStatus } of events) {
              result += `${id}\t`;
              result += `${data.type}\t`;
              result += `"${requestDescription(data)}"\t`;
              result += [
                data.principal,
                extractApproverName(data, false),
                formatDateTimeForCsv(logData?.lastUpdatedTimestamp ?? 0),
                initialStatus,
                `"${statusDescriptions[initialStatus]({
                  ...data,
                  ...logData,
                })}"`,
                data.reason ?? "",
              ].join("\t");
              result += "\n";
            }
        })()
      );
    }
    await Promise.all(promises);
    return result;
  };

export const RequestExport: React.FC<{
  requests: FirestoreDoc<PermissionRequest>[];
}> = ({ requests }) => {
  const tenantId = useContext(Tenant);
  const makeDetailedTsv = useCallback(
    (data: FirestoreDoc<PermissionRequest>[]) => toDetailedTsv(tenantId)(data),
    [tenantId]
  );
  return (
    <Export
      data={requests}
      filename="p0-requests"
      title="Export requests"
      options={{
        simple: {
          label: "simple list",
          blob: toTsv,
          extension: "tsv",
        },
        detailed: {
          label: "detailed history",
          blob: makeDetailedTsv,
          extension: "tsv",
        },
      }}
    />
  );
};
