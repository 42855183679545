import { InfoCircleOutlined } from "@ant-design/icons";
import { Descriptions, List, Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { Dictionary, capitalize, keys, omit, upperFirst } from "lodash";
import { ReactNode } from "react";
import { Node } from "shared/graph/types";
import { ProviderOrAll } from "shared/types/assessment";
import {
  AssessmentNodes,
  AssessmentSchemaItem,
  AssessmentSchemaMap,
} from "shared/types/assessment/data";

import { Timestamp } from "../cells/Timestamp";
import { NodeDescriptions } from "./NodeDescriptions";

type PropertyDatum = { key: string; label?: string; value: any; depth: number };

const { Item } = Descriptions;
const { Text } = Typography;

const innerLabelStyle = {
  fontWeight: "normal",
  fontStyle: "italic",
  fontSize: "small",
};

// flattens node data
function* generatePropertyData(data: {
  value: Dictionary<any>;
  schema?: Dictionary<AssessmentSchemaItem>;
  depth?: number;
}): Generator<PropertyDatum> {
  const { value, schema, depth: inputDepth } = data;
  const depth = inputDepth ?? 0;

  for (const key of keys(schema ?? omit(value, "key")).sort()) {
    const current = value[key];
    // handle primitive values, arrays
    if (typeof current !== "object" || Array.isArray(current)) {
      yield { key, value: current, depth };
      continue;
    }
    // handle nested objects recursively
    yield { key, value: "", depth };
    for (const result of generatePropertyData({
      value: current,
      depth: depth + 1,
    })) {
      const inner = capitalize(result.key);
      yield { ...result, key: `${key}${inner}`, label: inner };
    }
  }

  // Always put 'key' at end
  if ("key" in value) {
    yield { key: "key", value: value.key, depth };
  }
}

const renderArrayItem = (item: string) => {
  return <List.Item>{item}</List.Item>;
};

const nodePropertiesDisplayInternal = (
  node: Node<AssessmentNodes, keyof AssessmentNodes>,
  provider: ProviderOrAll
): ReactNode => {
  const { key, type, data } = node;
  if (!data) return null;

  const schema: Dictionary<AssessmentSchemaItem> = AssessmentSchemaMap[type];

  const value = { ...data, key };
  const keyValueData = [...generatePropertyData({ value, schema })];

  return (
    <NodeDescriptions theme="tight">
      {keyValueData.map(({ key, label: innerLabel, value, depth }) => {
        // schemaItem may be undefined if key is not in schema (e.g. a key with depth > 0)
        const schemaItem = schema?.[key] as AssessmentSchemaItem | undefined;
        const labeler = schemaItem?.label;
        const label =
          typeof labeler === "string"
            ? labeler
            : labeler?.[provider] ?? innerLabel ?? key;

        const help = depth === 0 ? schemaItem?.help : undefined;

        const propLabel = (
          <>
            <div>
              {upperFirst(label)}
              {help && (
                <>
                  &nbsp;
                  <GraphTooltip title={help}>
                    <InfoCircleOutlined style={{ color: "DarkGray" }} />
                  </GraphTooltip>
                </>
              )}
            </div>
            {label && (
              <div style={{ fontWeight: "normal", whiteSpace: "nowrap" }}>
                <Text type="secondary">{key}</Text>
              </div>
            )}
          </>
        );

        return (
          <Item
            key={key}
            label={propLabel}
            labelStyle={depth > 0 ? innerLabelStyle : undefined}
          >
            {value === undefined ? (
              <Text type="secondary">(No value)</Text>
            ) : Array.isArray(value) ? (
              <List
                dataSource={value.map((item) => item.toString())}
                renderItem={renderArrayItem}
              />
            ) : schemaItem?.type === "date" && typeof value === "number" ? (
              <Timestamp at={value} />
            ) : (
              String(value)
            )}
          </Item>
        );
      })}
    </NodeDescriptions>
  );
};

export const NodePropertiesDescriber =
  (provider: ProviderOrAll) =>
  (n: Node<AssessmentNodes, keyof AssessmentNodes>) =>
    nodePropertiesDisplayInternal(n, provider);
