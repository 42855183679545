import { Col, Row, Typography } from "antd";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { Dictionary } from "lodash";
import { useContext, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { assessmentParse } from "shared/assessment/issues/presets";
import { discover } from "shared/graph/discover";
import { DirectedGraph, isNode } from "shared/graph/types";
import { AssessmentScope, IamAssessment } from "shared/types/assessment";
import { NodeFor, TargetNodeType } from "shared/types/assessment/data";
import styled from "styled-components";
import { staticError } from "utils/console";

import { AssessmentNodes } from "../../../../../shared/types/assessment/data";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { useControls } from "../hooks/useControls";

const InventoryItemDiv = styled.div`
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

type InventoryLink<N extends TargetNodeType> = {
  label: string;
  filter: (data: NodeFor<N>["data"]) => boolean;
  predicate?: (scopes: AssessmentScope[]) => boolean;
  show: N;
  where: string;
};

const InventoryLink = <N extends TargetNodeType>(link: InventoryLink<N>) =>
  link;

const inventoryLinks = (assessment: IamAssessment) => {
  const isAws = assessment.targets.some((t) => t.integration === "aws");
  const isGcp = assessment.targets.some((t) => t.integration === "gcloud");
  const isWorkspace = assessment.targets.some(
    (t) => t.integration === "workspace"
  );

  // Since each link has the same "show" as query keytype, and every query term is a "first"
  // match, can achieve a "show only" behavior using a simple `{type}=...` query.
  //
  // If the "show" was different than the keytype, we would instead have to use the
  // `^{type}={attribute}:!"{keyword}"` pattern.
  return {
    credential: {
      _heading: InventoryLink({
        filter: () => true,
        label: "Credentials",
        show: "credential",
        where: "",
      }),
      key: InventoryLink({
        filter: ({ type }) => type === "key",
        label: "Keys",
        show: "credential",
        where: 'credential=type:"key"',
      }),
      federatedLogin: InventoryLink({
        filter: ({ type }) => type === "federated",
        label: "Federated Login",
        show: "credential",
        where: 'credential=type:"federated"',
      }),
      shortLived: InventoryLink({
        filter: ({ type }) => type === "short-lived",
        label: "Short-lived Login",
        show: "credential",
        where: 'credential=type:"short-lived"',
      }),
    },
    identity: {
      _heading: InventoryLink({
        filter: () => true,
        label: "Identities",
        show: "identity",
        where: "",
      }),
      user: InventoryLink({
        filter: ({ type }) => type === "user",
        label: "Users",
        show: "identity",
        where: 'identity=type:"user"',
      }),
      group: InventoryLink({
        filter: ({ type }) => type === "group",
        label: "Groups",
        show: "identity",
        where: 'identity=type:"group"',
      }),
      ...(isAws
        ? {
            awsIamRoles: InventoryLink({
              filter: ({ provider, type }) =>
                provider === "aws" && type === "service-account",
              label: "AWS IAM Roles",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "aws"),
              show: "identity",
              where: 'identity=type:"service-account" identity=provider:"aws"',
            }),
          }
        : {}),
      ...(isGcp
        ? {
            gcpServiceAccounts: InventoryLink({
              filter: ({ provider, type }) =>
                provider === "gcp" && type === "service-account",
              label: "GCP Service Accounts",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "gcloud"),
              show: "identity",
              where: 'identity=type:"service-account" identity=provider:"gcp"',
            }),
          }
        : {}),
    },
    grant: {
      _heading: InventoryLink({
        filter: () => true,
        label: "Grants",
        show: "grant",
        where: "",
      }),
      ...(isWorkspace
        ? {
            adminRoleBinding: InventoryLink({
              filter: ({ provider }) => provider === "workspace",
              label: "Workspace Admin Role Bindings",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "workspace"),
              show: "grant",
              where: 'grant=provider:"workspace"',
            }),
          }
        : {}),
      ...(isAws
        ? {
            awsPolicyAssignemnt: InventoryLink({
              filter: ({ provider }) => provider === "aws",
              label: "AWS Policy Assignments",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "aws"),
              show: "grant",
              where: 'grant=provider:"aws"',
            }),
          }
        : {}),
      ...(isGcp
        ? {
            gcpRoleBinding: InventoryLink({
              filter: ({ provider }) => provider === "gcp",
              label: "GCP Role Bindings",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "gcloud"),
              show: "grant",
              where: 'grant=provider:"gcp"',
            }),
          }
        : {}),
    },
  };
};

const _validateInventoryLinks: (
  assessment: IamAssessment
) => Dictionary<Dictionary<InventoryLink<any>>> = inventoryLinks;

const countNodes = async (
  link: InventoryLink<any>,
  graph: DirectedGraph<AssessmentNodes>
) => await discover(graph, isNode(link.show), assessmentParse(link.where));

const InventoryItem: React.FC<{
  link: InventoryLink<any>;
  graph: DirectedGraph<AssessmentNodes>;
}> = ({ link, graph }) => {
  const { controls } = useControls();
  const [count, setCount] = useState<number>();

  useGuardedEffect(
    (cancellation) => async () => {
      const count = (await countNodes(link, graph)).nodes.length;
      cancellation.guard(setCount)(count);
    },
    [graph, link],
    staticError
  );

  const [search] = useSearchParams();
  const url = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set("show", link.show);
    params.set("where", link.where);
    return `?${params.toString()}`;
  }, [link, search]);

  const isCurrent =
    controls.show === link.show && controls.where === link.where;

  return (
    <InventoryItemDiv>
      {count && !isCurrent ? (
        <Link to={url}>{link.label}</Link>
      ) : (
        <Typography.Text
          type="secondary"
          style={isCurrent ? { fontWeight: "bold" } : {}}
        >
          {link.label}
        </Typography.Text>
      )}
      &nbsp;
      <Typography.Text type="secondary">({count})</Typography.Text>
    </InventoryItemDiv>
  );
};

export const Inventory: React.FC<object> = () => {
  const { assessment } = useContext(SelectedAssessmentContext);
  const { graph } = useContext(ScopeContext);

  const allLinks = useMemo(
    () => (assessment.doc ? inventoryLinks(assessment.doc.data) : undefined),
    [assessment]
  );

  return graph && allLinks ? (
    <Row style={{ width: "100%" }}>
      {Object.values(allLinks).map((links, category) => (
        <Col key={category} span={8}>
          {Object.values(links).map((link, key) => (
            <InventoryItem key={key} graph={graph} link={link} />
          ))}
        </Col>
      ))}
    </Row>
  ) : null;
};
