import { SettingOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import {
  BaseDimensions,
  GraphVisualization,
  GraphVisualizationInstance,
  NodeFlowEdge,
  NodeFlowRenderer,
  NodeFlowSpec,
  NodeFlowTitler,
  NodePropertiesRenderer,
} from "components/Assessment/components/GraphVisualization";
import { VerticalSpacedDiv } from "components/divs";
import dagre from "dagre";
import { compact, flatten, uniqBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DiscoverMatch } from "shared/graph/discover";
import { keyOf } from "shared/graph/graph";
import { GraphSearchSettings } from "shared/graph/settings";
import { ConnectedNode, Node } from "shared/graph/types";
import { DirectedGraph } from "shared/graph/types";
import styled from "styled-components";
import { join } from "utils/join";

import { Collapsed, CollapsedNode, collapseGraph } from "./collapse";

type DivProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "ref"
>;

export type DiscoverVisualizationProps<G extends object> = DivProps & {
  matches: DiscoverMatch<DirectedGraph<G>>[];
  renderer: NodeFlowRenderer<G>;
  settings: GraphSearchSettings;
  titler: NodeFlowTitler<G>;
  describer: NodePropertiesRenderer<G>;
  viewStyle?: DivProps["style"];
};

const NodeExpandedDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const NodeInnerDiv = styled.div`
  border: solid 1px hsl(0 0 50%);
  border-radius: 3px;
  overflow-x: clip;
  height: ${BaseDimensions.node.height}px;
  width: ${BaseDimensions.node.width - 15}px;
  &:hover {
    border-color: hsl(0 0 0%);
  }
`;

const NodeCollapsedDiv = styled.div`
  align-content: center;
  height: ${BaseDimensions.node.height}px;
`;

const nodeDim = <G extends object>(
  node: Node<G & Collapsed<G>, "_collapsed">
) =>
  Math.ceil(((node.data as Collapsed<G>["_collapsed"]).nodes?.length ?? 1) / 2);

/** Renders a graph visualization of queried paths
 */
export const DiscoverVisualization = <G extends object>(
  props: DiscoverVisualizationProps<G>
) => {
  const {
    matches,
    renderer,
    settings,
    titler,
    describer,
    viewStyle,
    ...divProps
  } = props;
  const [open, setOpen] = useState<Record<string, boolean>>({});
  const [graphVisualizationInstance, setGraphVisualizationInstance] =
    useState<GraphVisualizationInstance<G & Collapsed<G>>>();

  const toggleOpen = useCallback(
    (key: string) => setOpen({ ...open, [key]: !open[key] }),
    [open]
  );

  const fullRenderer: NodeFlowRenderer<G & Collapsed<G>> = useMemo(
    () =>
      ({
        ...renderer,
        _collapsed: (node: ConnectedNode<Collapsed<G>, "_collapsed">) =>
          open[keyOf(node)] ? (
            <NodeExpandedDiv>
              {node.data.nodes.map((n, ix) => (
                <NodeInnerDiv
                  key={ix}
                  // Rendered data are in a useMemo
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={(e) => {
                    if (n.type !== "_collapsed") {
                      graphVisualizationInstance?.selectNode(n);
                      // stopping the propagation here prevents the event handler
                      // from being called on one of its parent nodes
                      // (e.g. the _collapsed node)
                      e.stopPropagation();
                    }
                  }}
                >
                  {(renderer as any)[n.type](n)}
                </NodeInnerDiv>
              ))}
            </NodeExpandedDiv>
          ) : (
            <NodeCollapsedDiv>
              {node.data.nodes.length}{" "}
              {pluralize(String(node.data.nodes[0].type))}
            </NodeCollapsedDiv>
          ),
      }) as any,
    [renderer, open, graphVisualizationInstance]
  );

  const fullTitler: NodeFlowTitler<G & Collapsed<G>> = useCallback(
    (node: any) =>
      node.type === "_collapsed" ? (
        <>
          {titler(node.data.nodes[0])} (
          {
            // Rendered data are in a useMemo
            // eslint-disable-next-line react/jsx-no-bind
            <a type="link" onClick={() => toggleOpen(keyOf(node))}>
              {open[keyOf(node)] ? "collapse" : "expand"}
            </a>
          }
          )
        </>
      ) : (
        titler(node)
      ),
    [open, titler, toggleOpen]
  );

  const fullDescriber: NodePropertiesRenderer<G & Collapsed<G>> = useCallback(
    (node: any) => (node.type === "_collapsed" ? null : describer(node)),
    [describer]
  );

  const { nodes, edges, warnings } = useMemo(() => {
    const before = performance.now();
    const resultLimitWarning =
      matches.length > settings.maxResults ? (
        <span key="result-limit">
          Query returned {matches.length} results, of which the first{" "}
          {settings.maxResults} are shown.
        </span>
      ) : null;
    let pathLimitWarning: JSX.Element | null = null;
    const limited = matches.slice(0, settings.maxResults);
    const edges: [ConnectedNode<G, keyof G>, ConnectedNode<G, keyof G>][] = [];

    // These nodes do not connect to any other nodes (e.g. if there is no query)
    const isolatedNodes: Record<string, ConnectedNode<G, keyof G>> = {};
    for (const n of limited) {
      if (n.matches.every((m) => m.paths.every((p) => p.length < 2))) {
        isolatedNodes[keyOf(n.node)] = n.node;
      }
      for (const m of n.matches) {
        if (m.paths.length > settings.maxPaths) {
          pathLimitWarning = (
            <span key="path-limit">
              Query returned one or more matches for which only the first{" "}
              {settings.maxPaths} paths are shown.
            </span>
          );
        }
        for (const p of m.paths.slice(0, settings.maxPaths)) {
          let s = p[0];
          for (const n of p.slice(1)) {
            edges.push([s, n]);
            s = n;
          }
        }
      }
    }
    const uniqueEdges = uniqBy(edges, (e) => e.map(keyOf));

    const collapsedEdges = collapseGraph(uniqueEdges);

    const howToFixWarning =
      resultLimitWarning || pathLimitWarning ? (
        <div key="edit-info">
          You can edit these limits using the <SettingOutlined /> control above.
        </div>
      ) : undefined;

    const uniqueNodes = uniqBy(
      [
        ...(Object.values(isolatedNodes) as CollapsedNode<G>[]),
        ...flatten(collapsedEdges),
      ],
      (n) => keyOf(n)
    );

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      // Inter-node separation in fractions of the node size
      // Approximately 15 px vertically and 90 px horizontally
      nodesep: 0.35,
      edgesep: 0,
      rankdir: "LR",
      ranksep: 0,
    });
    for (const node of uniqueNodes) {
      const isOpen = !!open[keyOf(node)];
      const dim = isOpen ? nodeDim(node as any) : 1;
      dagreGraph.setNode(keyOf(node), {
        // Extra ~60 pixels of horizontal padding
        width: (isOpen ? 2 : 1) + 0.25,
        // Extra ~18 pixels for node header and padding
        height: dim + 0.6,
      });
    }
    for (const [parent, child] of collapsedEdges) {
      dagreGraph.setEdge(keyOf(parent), keyOf(child));
    }
    dagre.layout(dagreGraph);
    let maxX = 0,
      maxY = 0;
    for (const n of uniqueNodes) {
      const d = dagreGraph.node(keyOf(n));
      maxX = Math.max(d.x, maxX);
      maxY = Math.max(d.y, maxY);
    }
    const flowNodes: NodeFlowSpec<G & Collapsed<G>>[] = uniqueNodes.map((n) => {
      const d = dagreGraph.node(keyOf(n));
      const isOpen = !!open[keyOf(n)];
      const dim = isOpen ? nodeDim(n as any) : 1;
      return {
        inner: n,
        display: "inner" as const,
        height: dim,
        width: isOpen ? 2 : 1,
        position: {
          d: 0,
          x: d.x - (maxX + (isOpen ? 1 : 0) + 0.625) / 2,
          y: d.y - (maxY + dim) / 2,
        },
      };
    });
    const flowEdges: NodeFlowEdge<G & Collapsed<G>>[] = collapsedEdges.map(
      ([parent, child]) => ({
        parent,
        child,
      })
    );
    const after = performance.now();
    /* eslint-disable no-console */
    console.log(
      "Time to construct graph visualization",
      (after - before).toFixed(1),
      "ms"
    );
    console.log("  nodes:", flowNodes.length, "edges:", flowEdges.length);
    /* eslint-enable no-console */
    return {
      nodes: flowNodes,
      edges: flowEdges,
      warnings: compact([
        resultLimitWarning,
        pathLimitWarning,
        howToFixWarning,
      ]),
    };
  }, [matches, open, settings]);

  // Ensure visualization is centered on focus node after graph changes (e.g. by navigation)
  useEffect(() => {
    graphVisualizationInstance?.resetHandler?.();
  }, [graphVisualizationInstance, matches]);

  return (
    <VerticalSpacedDiv {...divProps}>
      {warnings.length ? (
        <div
          style={{
            left: "+12px",
            height: 0,
            marginTop: "0",
            position: "relative",
            width: "fit-content",
            top: "+20px",
            zIndex: "1",
          }}
        >
          <Alert
            type="warning"
            message={join(warnings, " ")}
            // Float over graph visualization
            style={{
              maxWidth: "1000px",
            }}
          />
        </div>
      ) : null}
      <GraphVisualization<G & Collapsed<G>>
        edges={edges}
        nodes={nodes}
        renderer={fullRenderer}
        titler={fullTitler}
        describer={fullDescriber}
        viewStyle={viewStyle}
        onInit={setGraphVisualizationInstance}
      />
    </VerticalSpacedDiv>
  );
};
