import { Typography } from "antd";
import moment from "moment";

const { Text } = Typography;

export const Timestamp: React.FC<{ at: number }> = ({ at }) =>
  !at ? (
    <Text type="secondary">(Never)</Text>
  ) : (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{moment(at).format("LL")}</div>
      <div>
        <Text type="secondary" style={{ fontSize: "small" }}>
          {moment(at).fromNow()}
        </Text>
      </div>
    </div>
  );
