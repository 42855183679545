import { AggregatedNode } from "../../graph/aggregate";
import { NodeSearch } from "../../graph/search";
import { AssessmentScopeIntegration, ItemAssessmentScope } from "../assessment";
import { AssessmentNodes, TargetNodeType } from "./data";

export const monitorPriorities = ["CRITICAL", "HIGH", "MEDIUM", "LOW"] as const;

export type MonitorPriority = (typeof monitorPriorities)[number];

export type MonitorScope = AssessmentScopeIntegration | "all";

export type MonitorBase<K extends TargetNodeType = TargetNodeType> = {
  label: string;
  priority: MonitorPriority;
  cta: string;
  description?: string;
  show: K;
  scopes: MonitorScope[];
  hasBeenEvaluated?: boolean;
  /* Provides a user-friendly description of an automatically taken remediation action for this monitor by P0*/
  remediation?: (action: string) => string;
  /* True if this finding can be remediated in a VCS via the assessment/assign/remediate API endpoint*/
  vcsRemediateSupported?: boolean;
  /* Monitors that can be automatically managed over time through P0. */
  management?: {
    /* Prompts the user for input required to begin managing. If not present, no input is required. */
    inputPrompt?: string;
    /* Describes what P0 will do to manage this finding */
    description: string;
  };
};

// TODO: type form in MonitorAdmin with this
export type SavedMonitor = {
  archived: boolean;
  searchTerm: string;
  show: TargetNodeType;
} & Pick<
  MonitorBase<TargetNodeType>,
  "description" | "hasBeenEvaluated" | "label" | "priority" | "scopes"
>;

export type FixOptions = { scope: ItemAssessmentScope };

type Fix<
  A extends AssessmentNodes = AssessmentNodes,
  K extends TargetNodeType = TargetNodeType
> = {
  [P in MonitorScope]?: {
    description: string;
    type: "iac" | "shell";
    generate: (
      node: AggregatedNode<A, K, any>,
      options: FixOptions
    ) => string | undefined;
  };
};

export type Monitor<
  A extends AssessmentNodes = AssessmentNodes,
  K extends TargetNodeType = TargetNodeType
> = {
  search: NodeSearch<A>[];
  fix?: Fix<A, K>;
  revert?: Fix<A, K>;
} & MonitorBase<K>;

/** Helper tool to create monitors without type-casting */
export const Monitor = <
  A extends AssessmentNodes,
  K extends TargetNodeType = TargetNodeType
>(
  preset: Monitor<A, K>
) => preset;
