import {
  Edge as FlowEdge,
  Node as FlowNode,
  NodeMouseHandler,
  ReactFlow,
  ReactFlowInstance,
} from "@xyflow/react";
import { Position as FlowPosition } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Grid } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv, VerticalSpacedDiv } from "components/divs";
import { uniqBy } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { keyOf } from "shared/graph/graph";
import { ConnectedNode, Node, NodeOf } from "shared/graph/types";

import { NodeDrawer } from "./node/NodeDrawer";

/** Convert a node into a React link to its details page */
type NodeToLink<A extends object> = (
  node: Node<A, keyof A>
) => string | undefined;

type Position = {
  /** Additional integer micro-offset for this node */
  d: number;
  /** Integer x-offset of this node */
  x: number;
  /** Integer y-offset of this node */
  y: number;
};

export type NodeFlowRenderer<A extends object> = Partial<{
  [K in keyof A]: (node: ConnectedNode<A, K>) => ReactNode | undefined;
}>;

export type NodeFlowTitler<A extends object> = (node: NodeOf<A>) => ReactNode;

export type NodeFlowSpec<A extends object> = {
  inner: ConnectedNode<A, keyof A>;
  display: "inner" | "leaf" | "root";
  position: Position;
  height: number;
  width: number;
};

export type NodeFlowEdge<A extends object> = {
  parent: ConnectedNode<A, keyof A>;
  child: ConnectedNode<A, keyof A>;
};

export type NodePropertiesRenderer<A extends object> = (
  node: Node<A, keyof A>
) => ReactNode;

const GRAPH_BACKGROUND =
  "radial-gradient(ellipse, transparent, #fafcff 70%), linear-gradient(130deg, transparent 75%, rgba(0, 135, 68, .26) 54%, rgba(0, 135, 68, .26) 60%, transparent 70%), linear-gradient(112deg, transparent 35%, rgba(145, 235, 0, .15) 43%, rgba(145, 235, 0, .15) 0, transparent 65%)";

export const BaseDimensions = {
  node: {
    height: 42,
    // Height consumed by node header and padding
    heightOverhead: 18,
    width: 250, // pixels
  },
  spacing: {
    // Node width and height scale
    x: 250,
    y: 50,
    // Additional spacing for node type clusters
    offsetX: 30,
    offsetY: 20,
  },
};

const flowDimensions = ({ md, lg }: Partial<Record<Breakpoint, boolean>>) => ({
  ...BaseDimensions,
  view: {
    // Use the full limits of the viewport unless it is particularly small
    height: "max(500px, calc(100vh - 90px))",
    // Aligns width as sidebar + padding change
    width: `calc(100vw - ${lg ? "250px" : md ? "130px" : "110px"})`,
  },
  zoom: lg ? 1 : 0.75,
});

const FlowNodeLabel = <A extends object>({
  link,
  node,
  renderer,
  titler,
}: {
  link?: NodeToLink<A>;
  node: NodeFlowSpec<A>;
  renderer: NodeFlowRenderer<A>;
  titler: NodeFlowTitler<A>;
}) => {
  const title = useMemo(() => titler(node.inner), [titler, node]);
  const to = useMemo(() => link?.(node.inner), [link, node]);
  return (
    <VerticalSpacedDiv
      style={{
        justifyContent: "space-between",
        gap: "0",
        minHeight: `${
          BaseDimensions.node.height * node.height +
          BaseDimensions.node.heightOverhead
        }px`,
      }}
    >
      <div>
        {to ? (
          <Link to={to} relative="path">
            {title}
          </Link>
        ) : (
          title
        )}
      </div>
      <ClipDiv maxWidth={`${BaseDimensions.node.width * node.width}px`}>
        {renderer[node.inner.type]?.(node.inner) ?? (
          <GraphTooltip title={node.inner.key}>{node.inner.key}</GraphTooltip>
        )}
      </ClipDiv>
    </VerticalSpacedDiv>
  );
};

export type GraphVisualizationInstance<A extends object> = {
  selectNode: (node: NodeOf<A> | undefined) => void;
  resetHandler: () => void;
};

/** Renders a graph of nodes as a network visualization */
export const GraphVisualization = <A extends object>(props: {
  link?: NodeToLink<A>;
  renderer: NodeFlowRenderer<A>;
  titler: NodeFlowTitler<A>;
  describer: NodePropertiesRenderer<A>;
  nodes: NodeFlowSpec<A>[];
  edges: NodeFlowEdge<A>[];
  viewStyle?: React.CSSProperties;
  onInit?: (instance: GraphVisualizationInstance<A>) => void;
}) => {
  const { link, renderer, titler, describer, nodes, edges, viewStyle, onInit } =
    props;
  const breakpoints = Grid.useBreakpoint();
  const [selectedNode, setSelectedNode] = useState<NodeOf<A>>();
  const [flowInstance, setFlowInstance] = useState<ReactFlowInstance>();

  const dimensions = useMemo(() => flowDimensions(breakpoints), [breakpoints]);
  const toFlowNode = useMemo(() => {
    return (spec: NodeFlowSpec<A>): FlowNode => {
      const { inner, display, position, width } = spec;
      return {
        id: keyOf(inner),
        data: {
          label: (
            <FlowNodeLabel
              node={spec}
              link={link}
              renderer={renderer}
              titler={titler}
            />
          ),
        },
        position: {
          x:
            position.x * dimensions.spacing.x +
            position.d * dimensions.spacing.offsetX,
          y:
            position.y * dimensions.spacing.y +
            position.d * dimensions.spacing.offsetY,
        },
        sourcePosition:
          display === "leaf" ? FlowPosition.Left : FlowPosition.Right,
        targetPosition:
          display === "root" ? FlowPosition.Right : FlowPosition.Left,
        width: dimensions.node.width * width,
      };
    };
  }, [dimensions, link, renderer, titler]);

  const flowNodes: FlowNode[] = useMemo(
    () => uniqBy(nodes.map(toFlowNode), "id"),
    [nodes, toFlowNode]
  );

  const flowEdges: FlowEdge[] = useMemo(
    () =>
      uniqBy(
        edges.map(({ child, parent }) => ({
          id: `${keyOf(parent)}-${keyOf(child)}`,
          source: keyOf(parent),
          target: keyOf(child),
        })),
        "id"
      ),
    [edges]
  );

  const reset = useCallback(() => {
    flowInstance?.setCenter(
      BaseDimensions.node.width / 2,
      (BaseDimensions.node.height + BaseDimensions.node.heightOverhead) / 2,
      { zoom: 0.8 }
    );
  }, [flowInstance]);

  const instance = useMemo<GraphVisualizationInstance<A>>(
    () => ({
      selectNode: setSelectedNode,
      resetHandler: reset,
    }),
    [setSelectedNode, reset]
  );

  // React flow abandons edges on the graph on update
  // Force clear to ensure that zombie edges do not remain on search update
  useEffect(() => {
    flowInstance?.setEdges([]);
  }, [flowEdges, flowInstance]);

  // Center nodes on first load
  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    onInit?.(instance);
  }, [instance, onInit]);

  const closeNodeDrawer = useCallback(() => setSelectedNode(undefined), []);
  const handleNodeClick: NodeMouseHandler<FlowNode> = useCallback(
    (_, node) => {
      const spec = nodes.find((n) => keyOf(n.inner) === node.id);
      if (spec) setSelectedNode?.(spec.inner);
    },
    [nodes, setSelectedNode]
  );

  return (
    <div
      style={{
        height: dimensions.view.height,
        width: dimensions.view.width,
        backgroundColor: "rgb(250, 252, 255)",
        backgroundImage: GRAPH_BACKGROUND,
        // Necessary for inline NodeDrawer; see https://4x.ant.design/components/drawer/
        position: "relative",
        ...viewStyle,
      }}
    >
      <ReactFlow
        nodes={flowNodes}
        edges={flowEdges}
        nodesConnectable={false}
        onInit={setFlowInstance}
        onNodeClick={handleNodeClick}
      />
      {selectedNode?.type && selectedNode.type !== "_collapsed" ? (
        <NodeDrawer
          onClose={closeNodeDrawer}
          node={selectedNode}
          describer={describer}
        />
      ) : null}
    </div>
  );
};
