import { LoginOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, Row, Typography } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Splash } from "../Splash";
import { ORG_SLUGS_LOCALSTORAGE_KEY } from "./hook";

const MAX_ORGS_TO_DISPLAY = 5;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

/** Renders an account selector
 *
 * Clicking an account simply navigates to that account's base URL.
 *
 * If the URL includes a 'path' parameter, will navigate to that path,
 * relative to the account's base URL. All other parameters will be
 * passed transparently.
 */
export const AccountSelect: React.FC = () => {
  const flags = useFlags();
  const [addingNewOrg, setAddingNewOrg] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const previousOrgs = useMemo(() => {
    const localStorageOrgs = localStorage.getItem(ORG_SLUGS_LOCALSTORAGE_KEY);
    if (localStorageOrgs === null) return [];
    const parsed: [string, any][] = Object.entries(
      JSON.parse(localStorageOrgs)
    );
    const sorted = sortBy(
      parsed,
      ([_, org]) => -new Date(org.lastAccess)
    ).slice(0, MAX_ORGS_TO_DISPLAY);
    return sorted;
  }, []);

  const forceAddAccount = useMemo(
    () => Object.keys(previousOrgs).length === 0 || addingNewOrg,
    [addingNewOrg, previousOrgs]
  );

  const addNewOrg = useCallback(() => setAddingNewOrg(true), []);

  const { path, passParams } = useMemo(() => {
    const path = searchParams.get("path");
    searchParams.delete("path");
    const passParams = searchParams.toString();
    return { path, passParams };
  }, [searchParams]);

  return (
    <Splash>
      <Card>
        {previousOrgs ? (
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Typography.Title level={4}>Welcome back!</Typography.Title>
            <div style={{ marginBottom: "15px" }}>
              {previousOrgs.map(([key, org], ix) => (
                <Row
                  key={key}
                  align="middle"
                  style={{
                    marginBottom: "15px",
                    gap: "1em",
                    flexFlow: "row nowrap",
                  }}
                >
                  <Col span={8}>
                    <FullWidthButton
                      type="default"
                      href={`/o/${key}${path ? `/${path}` : ""}${
                        passParams ? `?${passParams}` : ""
                      }`}
                    >
                      <LoginOutlined /> {key}
                    </FullWidthButton>
                  </Col>
                  <Col span={16} style={{ textAlign: "right" }}>
                    <Typography.Text type="secondary">
                      <span>
                        {ix === 0 ? "Last accessed (on this device) " : "... "}
                      </span>
                      <span>{moment(org.lastAccess).fromNow()}</span>
                    </Typography.Text>
                  </Col>
                </Row>
              ))}
            </div>
            {!forceAddAccount && (
              <Button type="link" onClick={addNewOrg}>
                Sign into a different account
              </Button>
            )}
          </div>
        ) : null}
        {forceAddAccount && <NewAccount />}
        {flags.allowSignups ? (
          <>
            <Divider> or </Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button type="primary" href="/create-account">
                Create Account
              </Button>
            </div>
          </>
        ) : null}
      </Card>
    </Splash>
  );
};

const NewAccount: React.FC<object> = () => {
  const [accountId, setAccountId] = useState<string>();
  const navigate = useNavigate();

  const onInputAccountId = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAccountId(event.target.value.trim());
    },
    [setAccountId]
  );

  const onClickLogin = useCallback(() => {
    navigate(`o/${accountId}`);
  }, [accountId, navigate]);

  const onKeyUpValue = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        navigate(`o/${accountId}`);
      }
    },
    [accountId, navigate]
  );

  return (
    <>
      <div>Please enter your account ID</div>
      <Input onChange={onInputAccountId} onKeyUp={onKeyUpValue} />
      <Button
        icon={<LoginOutlined />}
        type="primary"
        disabled={!accountId || accountId === ""}
        onClick={onClickLogin}
      >
        Login
      </Button>
    </>
  );
};
