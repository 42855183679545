import { Typography } from "antd";
import { RoutingRuleEditorContext } from "components/Jit/Routing/store/RoutingRuleEditorContext";
import { getDefaultRequestorPreviewRule } from "components/Jit/Routing/utils";
import { StateRadioGroup } from "components/common/StateRadioGroup";
import { FormInput } from "components/common/forms/FormInput";
import { useCallback, useContext } from "react";
import { requestorTypes } from "shared/types/workflow/constants";
import { IdpGroup, RequestorRule } from "shared/types/workflow/types";
import { widetype } from "shared/util/collections";

import { IdpGroupInput } from "../IdpGroupInput";
import { RequestorPreview } from "../previews/RequestorPreview";

export const RequestorEditor: React.FC = () => {
  const { requestor, setRequestor, setRequestorType } = useContext(
    RoutingRuleEditorContext
  );

  const handleSelectRequestorType = useCallback(
    (type: RequestorRule["type"]) => {
      setRequestorType(type);
    },
    [setRequestorType]
  );

  const handleUidChange = useCallback(
    (uid: string) => {
      if (requestor?.type !== "user") {
        return;
      }
      setRequestor({ ...requestor, uid });
    },
    [requestor, setRequestor]
  );

  const handleIdpGroupChange = useCallback(
    (idpGroup: IdpGroup) => {
      setRequestor(idpGroup);
    },
    [setRequestor]
  );

  return (
    <>
      <Typography.Title level={4}>Requestor</Typography.Title>
      <StateRadioGroup
        value={requestor?.type}
        onChange={handleSelectRequestorType}
        items={widetype.keys(requestorTypes).map((req) => ({
          value: req,
          display: (
            <RequestorPreview requestor={getDefaultRequestorPreviewRule(req)} />
          ),
        }))}
      />
      {requestor?.type === "user" && (
        <FormInput
          value={requestor.uid}
          onChange={handleUidChange}
          label="User Email"
          required="You must set a user email"
        />
      )}
      {requestor?.type === "group" && (
        <IdpGroupInput value={requestor} onChange={handleIdpGroupChange} />
      )}
    </>
  );
};
