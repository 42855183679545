import { SplunkComponents } from "shared/integrations/event-collectors/splunk/components";

import { Install, InstallProps } from "../Install/Install";
import { Logo } from "../Logo";
import { splunkInstaller } from "./install";

export const SplunkIconUrl =
  "https://dev.splunk.com/static/favicon-e60c968e8ff3cc2f4fb869588e83afc6.ico";

export const SplunkIcon = (
  <Logo logo={SplunkIconUrl} title="Splunk" size={14} />
);
export const Splunk = (
  props: Pick<
    InstallProps<typeof SplunkComponents>,
    "cardOverride" | "onInstall"
  >
) => (
  <Install<typeof SplunkComponents>
    components={SplunkComponents}
    installer={splunkInstaller}
    integration="splunk"
    itemKind="token"
    logo={SplunkIconUrl}
    title={"Splunk"}
    {...props}
  />
);
