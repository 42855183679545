import { max } from "../../graph/aggregate";
import { Reducers } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { IdentityAggregates } from "../../types/assessment/data/identity";
import { grantAggregate, keyAggregate, permissionAggregate } from "./shared";

export const identityReducers: Reducers<AssessmentNodes, IdentityAggregates> = {
  credentials: keyAggregate("credential"),
  lastAuthEvent: max("credential", (n) => n.lastAuthnTime),
  grants: grantAggregate,
  permissions: permissionAggregate,
  risks: keyAggregate("risk"),
  consumers: keyAggregate("consumer"),
};
