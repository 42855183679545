import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { capitalize } from "lodash";
import { Node } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";
import { assertNever } from "utils/assert";

import { CellInner } from "./Inner";

const { Text } = Typography;

export const Lateral: React.FC<{ node: Node<AssessmentNodes, "lateral"> }> = ({
  node,
}) => {
  const { data } = node;

  const label =
    data.type === "grant"
      ? "direct impersonation"
      : data.type === "resource"
      ? "service identity"
      : assertNever(data.type);

  return (
    <GraphTooltip
      width="300px"
      title={
        <div>
          <div>Lateral movement via {label}</div>
          <div>
            Service: <Text code>{data.service}</Text>
          </div>
          <div>
            Resource: <Text code>{data.resource}</Text>
          </div>
        </div>
      }
    >
      <div>{capitalize(label)} to</div>
      <CellInner>{data.identity}</CellInner>
    </GraphTooltip>
  );
};
