import { useIntegration } from "hooks/useIntegration";
import { KnownFirestoreDoc } from "providers/FirestoreProvider";
import { createContext } from "react";
import { AwsIntegration } from "shared/integrations/resources/aws/types";
import { SshIntegration } from "shared/integrations/resources/ssh/types";

export type Integrations = {
  aws?: KnownFirestoreDoc<AwsIntegration>;
  ssh?: KnownFirestoreDoc<SshIntegration>;
};

export const IntegrationsContext = createContext<Integrations>({
  aws: undefined,
  ssh: undefined,
});

export const IntegrationsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const awsIntegration = useIntegration<AwsIntegration>("aws");
  const sshIntegration = useIntegration<SshIntegration>("ssh");

  return (
    <IntegrationsContext.Provider
      value={{ aws: awsIntegration, ssh: sshIntegration }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};
