import { Button, Checkbox, CheckboxOptionType, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { newApprovalRuleOfType } from "components/Jit/Routing/store/mutations";
import { getDefaultApprovalPreviewRule } from "components/Jit/Routing/utils";
import { StateRadioGroup } from "components/common/StateRadioGroup";
import {
  StyledFormItem,
  standardFormItemCols,
  standardSubmitButtonCol,
} from "components/common/forms/styles";
import { useCallback, useContext, useMemo } from "react";
import { accessTypes } from "shared/types/workflow/constants";
import {
  ApprovalRule,
  EscalationApprovalRule,
  IdpGroup,
  RequestorProfile,
  UserApprovalOptions,
} from "shared/types/workflow/types";
import { widetype } from "shared/util/collections";

import { EscalationServiceInput } from "../EscalationServiceInput";
import { IdpGroupInput } from "../IdpGroupInput";
import { RequestorProfileInput } from "../RequestorProfileInput";
import { ApprovalPreview } from "../previews/ApprovalPreview";
import { LocalAccessRuleContext } from "./AccessEditor";

type Props = {
  onSave: (rule: ApprovalRule) => void;
  onDelete?: () => void;
};

export const AccessRuleEditor: React.FC<Props> = ({ onSave, onDelete }) => {
  const { localRule, setLocalRule, isDirty, isNew } = useContext(
    LocalAccessRuleContext
  );

  const handleSelectAccessType = useCallback(
    (type: ApprovalRule["type"]) => {
      setLocalRule(newApprovalRuleOfType(type));
    },
    [setLocalRule]
  );

  const handleIdpGroupChange = useCallback(
    (idpGroup: IdpGroup) => {
      setLocalRule(idpGroup);
    },
    [setLocalRule]
  );

  const handlePagerDutyServiceChange = useCallback(
    (escalationRule: EscalationApprovalRule) => {
      setLocalRule(escalationRule);
    },
    [setLocalRule]
  );

  const handleRequestorProfileChange = useCallback(
    (requestorProfile: RequestorProfile) => {
      if (localRule?.type !== "requestor-profile") {
        return;
      }

      const newRule =
        requestorProfile.directory === "okta"
          ? // Only showing property override for Okta right now, as Workspace has a more opinionated way of accessing the user's manager.
            { ...localRule, ...requestorProfile }
          : {
              type: "requestor-profile" as const,
              directory: requestorProfile.directory,
            };
      setLocalRule(newRule);
    },
    [setLocalRule, localRule]
  );

  const routingOptions = useMemo(() => {
    if (!localRule) {
      return [];
    }

    if (localRule.type === "deny") {
      return [];
    }

    const options: CheckboxOptionType[] = [];
    options.push({
      value: "requireReason",
      label: "Require reason with request",
    });
    if (
      localRule.type === "p0" ||
      localRule.type === "group" ||
      localRule.type === "escalation" ||
      localRule.type === "requestor-profile"
    ) {
      options.push({
        value: "allowOneParty",
        label: "Allow self-approvals",
      });
    }
    return options;
  }, [localRule]);

  const handleChangeApprovalOptions = useCallback(
    (options: CheckboxValueType[]) => {
      if (!localRule) {
        return;
      }

      if (localRule.type === "deny") {
        return;
      }

      setLocalRule({
        ...localRule,
        options: convertApprovalOptionsArrayToObject(
          options as ApprovalOptionsArray
        ),
      });
    },
    [localRule, setLocalRule]
  );

  const saveRule = useCallback(
    () => localRule && onSave(localRule),
    [localRule, onSave]
  );

  return !localRule ? (
    <Typography.Paragraph>No access rule selected.</Typography.Paragraph>
  ) : (
    <>
      <StateRadioGroup
        value={localRule.type}
        onChange={handleSelectAccessType}
        items={widetype.keys(accessTypes).map((acc) => ({
          value: acc,
          display: (
            <ApprovalPreview approval={getDefaultApprovalPreviewRule(acc)} />
          ),
        }))}
      />
      {localRule.type === "group" && (
        <IdpGroupInput value={localRule} onChange={handleIdpGroupChange} />
      )}
      {localRule.type === "escalation" && (
        <EscalationServiceInput
          value={localRule}
          onChange={handlePagerDutyServiceChange}
        />
      )}
      {localRule.type === "requestor-profile" && (
        <RequestorProfileInput
          value={localRule}
          onChange={handleRequestorProfileChange}
        />
      )}
      {routingOptions.length > 0 && localRule.type !== "deny" && (
        <StyledFormItem label="Options" {...standardFormItemCols}>
          <Checkbox.Group
            options={routingOptions}
            value={convertApprovalOptionsObjectToArray(localRule.options)}
            onChange={handleChangeApprovalOptions}
          />
        </StyledFormItem>
      )}
      <StyledFormItem {...standardSubmitButtonCol}>
        <Button type="primary" onClick={saveRule} disabled={!isDirty}>
          {isNew ? "Add" : "Update"}
        </Button>
        {onDelete && (
          <Button type="text" onClick={onDelete}>
            Remove
          </Button>
        )}
      </StyledFormItem>
    </>
  );
};

const convertApprovalOptionsObjectToArray = (
  approvalOptions: UserApprovalOptions | undefined
) => {
  const options = [];
  if (approvalOptions?.allowOneParty) {
    options.push("allowOneParty");
  }
  if (approvalOptions?.requireReason) {
    options.push("requireReason");
  }
  return options;
};

type ApprovalOptionsArray = ("allowOneParty" | "requireReason")[];

const convertApprovalOptionsArrayToObject = (options: ApprovalOptionsArray) => {
  return Object.fromEntries(options.map((o) => [o, true]));
};
