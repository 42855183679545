import { groupBy } from "lodash";
import { STALE_CREDENTIAL_MILLIS } from "shared/assessment/issues/presets";
import { DAYS } from "shared/time";
import { IdentityAggregates } from "shared/types/assessment/data";

export const credentialsByUsage = (
  credentials: IdentityAggregates["credentials"]
) => {
  const threshold = Date.now() - 90 * DAYS;
  return groupBy(credentials, (c) =>
    c.lastAuthnTime >= threshold ? ("used" as const) : ("unused" as const)
  );
};

export const credentialsByStaleness = (
  credentials: IdentityAggregates["credentials"]
) => {
  const threshold = Date.now() - STALE_CREDENTIAL_MILLIS;
  return groupBy(credentials, (c) =>
    c.createdTime && c.createdTime >= threshold
      ? ("fresh" as const)
      : ("stale" as const)
  );
};
