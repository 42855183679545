import { useCallback } from "react";

import { RoutingEditAlert } from "..";
import { useRoutingRulesStore } from "../../../../store/routingRulesStore";
import CodeEditor from "../CodeEditor";
import { DEFAULT_DOCUMENT, EXAMPLE_DOCUMENT } from "../utils";

export const RulesView: React.FC<{
  canSubmit: boolean;
  handleSubmitWorkflow: (value: string) => Promise<void>;
}> = ({ canSubmit, handleSubmitWorkflow }) => {
  const { yamlRules, setYamlRules } = useRoutingRulesStore();

  const setDefaultRules = useCallback(
    () => setYamlRules(DEFAULT_DOCUMENT),
    [setYamlRules]
  );
  const setExampleRules = useCallback(
    () => setYamlRules(EXAMPLE_DOCUMENT),
    [setYamlRules]
  );
  return (
    <>
      {canSubmit && <RoutingEditAlert />}
      <CodeEditor
        rule={yamlRules}
        setDefaultRule={setDefaultRules}
        setSampleExample={setExampleRules}
        handleSubmitWorkflow={canSubmit ? handleSubmitWorkflow : undefined}
      />
    </>
  );
};
