import { WarningTwoTone } from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

export const WarningTriangle: React.FC<
  Omit<AntdIconProps, "ref" | "twoToneColor">
> = (props) => (
  <WarningTwoTone
    {...props}
    twoToneColor={["hsl(50 100% 40%)", "hsl(50 100% 90%)"]}
  />
);
