import { RoutingRulePopover } from "components/Jit/Routing/styles";
import { ReactNode } from "react";

export const SomeRulePopover: React.FC<{
  integrationText: string;
  icon: ReactNode;
}> = ({ integrationText, icon }) => (
  <RoutingRulePopover
    icon={icon}
    text={integrationText}
    popoverContent={
      <>
        <p>
          This rule can be chained with another matching rule to create
          something akin to an &quot;optional&quot; or &quot;additional&quot;
          approval choice.
        </p>
        <p>
          For more info, you can check out:{" "}
          <a
            href="https://docs.p0.dev/just-in-time-access/request-routing#what-are-some-rules"
            target="_blank"
            rel="noreferrer"
          >
            the P0 docs for optional rules
          </a>
          .
        </p>
      </>
    }
  />
);
