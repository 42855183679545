import { Select } from "antd";
import { BaseOptionType } from "antd/lib/select";
import { Tenant } from "components/Login";
import {
  StyledFormItem,
  standardFormItemCols,
} from "components/common/forms/styles";
import { useCallback, useContext, useMemo } from "react";
import { EscalationApprovalRule } from "shared/types/workflow/types";

import * as firestoreProvider from "../../../../providers/FirestoreProvider";

type PagerDutyService = {
  name: string;
  id: string;
};

export const EscalationServiceInput: React.FC<{
  value: EscalationApprovalRule;
  onChange: (rule: EscalationApprovalRule) => void;
}> = ({ value, onChange }) => {
  const tenantId = useContext(Tenant);
  const servicesDocs =
    firestoreProvider.useFirestoreCollection<PagerDutyService>(
      `o/${tenantId}/integrations/pagerduty/services`,
      {
        live: true,
      }
    );

  const options = useMemo(
    () =>
      servicesDocs?.map((p) => (
        <Select.Option key={p.data.id}>{p.data.name}</Select.Option>
      )),
    [servicesDocs]
  );

  const currentlyWrittenIds = useMemo(() => {
    return value.services ?? [];
  }, [value]);

  const onUpdate = useMemo(
    () => async (selected: string[]) => {
      onChange({
        ...value,
        services: selected,
      });
    },
    [onChange, value]
  );

  const filterService = useCallback(
    (input: string, option?: BaseOptionType) =>
      option?.children
        ?.toString()
        .toLowerCase()
        ?.includes(input.toLowerCase()) ?? false,
    []
  );

  return (
    <>
      <StyledFormItem
        label="PagerDuty Services"
        rules={[
          {
            required: true,
            message: "Please select at least one PagerDuty service",
          },
        ]}
        {...standardFormItemCols}
      >
        <Select
          mode="multiple"
          allowClear
          filterOption={filterService}
          style={{ flexGrow: 1 }}
          placeholder="Select a service"
          defaultValue={currentlyWrittenIds}
          onChange={onUpdate}
        >
          {options}
        </Select>
      </StyledFormItem>
    </>
  );
};
