import { Shutdownable } from ".";
import { MfaStatus } from "./assessment/data";

export const DirectoryKeys = ["azure-ad", "okta", "workspace"] as const;
export type DirectoryKey = (typeof DirectoryKeys)[number];

export type Group = {
  id: string;
  label?: string;
};

export const IdentityTypes = ["group", "user"] as const;
export type IdentityType = (typeof IdentityTypes)[number];

export const GroupTypes = ["group", "federated"] as const;
export type GroupType = (typeof GroupTypes)[number];

export type DirectoryUser = {
  type: "user";
  department?: string;
  directory: string;
  id: string;
  label: string;
  lastLogin: string | undefined;
  manager?: string;
  mfa: MfaStatus;
  aliases: string[];
};

export type GroupPrivilege =
  /** Can add group members */
  | "add"
  /** Can approve group join requests */
  | "approve"
  /** Can directly join this group */
  | "join"
  /** Can view group data content (e.g., messages if this is a mailing group) */
  | "view";

// Missing indicates no access
export type GroupAccessIdentity =
  /** Action can only be performed by group admins */
  | "admin"
  /** Action can be performed by anyone in the organization domain */
  | "domain"
  /** Action can be performed by any member of the group */
  | "group"
  /** Action can only be performed by an invited user */
  | "invited"
  /** Action can only be performed by the resource owner */
  | "owner"
  /** Action can be performed by anyone */
  | "public"
  /** Access principal is unknown */
  | "unknown";

export type DirectoryGroup = Group & {
  access?: Partial<Record<GroupPrivilege, GroupAccessIdentity>>;
  /** The name of the directory in which this group resides */
  directory: string;
  /** Human-friendly name for this group */
  label: string;
  /** Group members */
  members: DirectoryMember[];
  // `type` is of unknown significance?
  type: GroupType;
};

export type DirectoryIdentity = DirectoryGroup | DirectoryUser;
export type DirectoryMember = Pick<DirectoryIdentity, "id" | "label" | "type">;

export const isDirectoryUser = (
  identity: DirectoryIdentity
): identity is DirectoryUser => identity.type === "user";

export const DEFAULT_MANAGER_EMAIL_FIELD = "manager";

/** Methods for reading and mutating a single user */
export interface UserDirectory {
  /** Adds a user to a group */
  addToGroup(email: string, groupId: string): Promise<void>;

  /** Gets a user's groups
   *
   * Includes transitively joined groups.
   */
  groups(email: string): AsyncGenerator<DirectoryGroup>;

  /** List all users */
  list(): AsyncGenerator<DirectoryUser>;

  /**
   * Get a user's manager's email as defined in the directory.
   */
  managerEmail(email: string): Promise<string | undefined>;

  /** Removes a user from a group */
  removeFromGroup(email: string, groupId: string): Promise<void>;
}

export type GroupDirectoryListOptions = {
  /** If true, only return groups that may be granted by P0 */
  grantableOnly: boolean;
  /** If true, gather access settings for the groups */
  expandAccess: boolean;
  /** If true, return group members */
  expandMembers: boolean;
};

export type GroupDirectoryMembersOptions = {
  includeTransitive: boolean;
  usersOnly: boolean;
};

export interface GroupDirectory {
  /** Get a group's details from its ID */
  get(groupId: string): Promise<DirectoryGroup>;

  /** List all groups */
  list(options: GroupDirectoryListOptions): AsyncGenerator<DirectoryGroup>;

  /** List all members of groups. Defaults to returning only direct members, unless includeTransitive is provided in options */
  members(
    groupId: string,
    options: GroupDirectoryMembersOptions
  ): AsyncGenerator<DirectoryIdentity>;
}

export interface Directory<K extends DirectoryKey = DirectoryKey>
  extends Shutdownable {
  key: K;
  group: GroupDirectory;
  user: UserDirectory;
}

export type DirectoryProcessor = {
  /** Process Users from directory integration specific api contract*/
  users(users: DirectoryUser[]): {
    users: DirectoryUser[];
    aliases: Record<string, string>;
  };
  /** Process group members from directory integration specific api contract */
  groupMembers(
    members: DirectoryIdentity[],
    aliases: Record<string, string>
  ): DirectoryIdentity[];
};
