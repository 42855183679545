import { Element, InstallSpec, ItemComponent } from "../../../install/types";
import { UUID_PATTERN } from "../../directories/azure-ad/types";

const HEC_URL_VALIDATION_ERROR_MSG =
  "HEC endpoint should have the form `https://<host>[:<port>]`";

export const SplunkComponents = InstallSpec({
  "audit-log": ItemComponent({
    type: "string",
    label: "HTTP Event Collector",
    description:
      "Allows P0 to send access events and security findings to a Splunk HTTP event collector",
    schema: {
      endpoint: Element({
        label: "HEC endpoint",
        type: "string",
        validator: async (_id, field) => {
          try {
            if (!field) return HEC_URL_VALIDATION_ERROR_MSG;
            const url = new URL(field);
            if (url.protocol !== "https:") {
              return HEC_URL_VALIDATION_ERROR_MSG;
            }
          } catch (e) {
            return HEC_URL_VALIDATION_ERROR_MSG;
          }
        },
      }),
      token: Element({
        label: "HEC token",
        type: "encrypted",
        validator: async (_id, field) => {
          if (!field || !field.clearText?.match(UUID_PATTERN))
            return "Valid HEC token is required";
        },
      }),
    },
    maxItems: 1,
  }),
});
