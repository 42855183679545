import { Provider } from "../../assessment";
import { RiskScore } from "../../catalog";
import { DirectoryGroup } from "../../directory";

export const Usages = ["unused", "used", "unknown"] as const;

export type GrantCondition = {
  title?: string;
  description?: string;
  expression: string;
};

/** Uniquely identifies a single grant */
export type GrantEntry = {
  condition: GrantCondition | undefined;
  createdAt?: number;
  isAncestorGrant: boolean;
  lastUsedAt?: number;
  parent: string;
  permissionSet: string;
  principal: string;
  principalType: IdentityType;
  resources: string[];
};

const IdentityTypes = [
  "domain",
  "federated",
  "group",
  "iam-group",
  "iam-user",
  "logged-in",
  "public",
  "service-account",
  "unknown",
  "user",
] as const;
export type IdentityType = (typeof IdentityTypes)[number];

const MfaStatuses = ["disabled", "enabled", "unknown"] as const;
export type MfaStatus = (typeof MfaStatuses)[number];

export type TrustedPrincipals = { trustPolicy: string; isConditional: boolean };

export type Identity = {
  access?: DirectoryGroup["access"];
  /** Department that manages this identity */
  department?: string;
  disabled?: boolean;
  isProviderManaged: boolean;
  label: string;
  /** Key of this identity's manager identity; only applies to users */
  manager?: string;
  mfa?: MfaStatus;
  /** This principal's parent resource
   *
   * Applies to service identities (GCP service acocunts, AWS IAM roles).
   */
  parent?: string;
  type: IdentityType;
  /** The identity provider for this principal
   *
   * May be undefined if unknown.
   */
  provider: Provider | undefined;
  trustedPrincipals?: TrustedPrincipals;
};

const CredentialTypes = ["federated", "key", "short-lived"] as const;
export type CredentialType = (typeof CredentialTypes)[number];

type Credential = {
  type: CredentialType;
  source: "azure-ad" | "csp" | "okta" | "workspace";
  createdTime?: number;
  status: "disabled" | "enabled" | undefined;
  lastAuthnTime: number;
  // Specifies the number of days of authentication lookback we have for this credential.
  maxAuthnLookbackDays?: number;
};

type CredentialConsumerBase = {
  isProviderManaged: boolean;
  label: string;
  lastUsedTime: number;
  identityType: IdentityType;
  provider: Provider;
  usageCount: number;
};

export type CredentialIpConsumer = CredentialConsumerBase & {
  type: "ip";
  organization?: string;
  userAgents?: string[];
};

export type CredentialPrincipalConsumer = CredentialConsumerBase & {
  type: "principal";
};
export type CredentialConsumer =
  | CredentialIpConsumer
  | CredentialPrincipalConsumer;

export type Resource = {
  /** The resource's (short) identifier */
  id: string;
  /** The full resource locator */
  locator: string;
  /** The resource's service */
  service: string;
  /** undefined if this is a service node */
  type: string | undefined;
};

export type PrivilegeUsage = {
  privilege: string;
  service: string;
  usage: Usage;
};

export type Grant = {
  condition: GrantCondition | string | undefined;
  disabled?: boolean;
  /** Whether this grants access to an externally managed service account */
  external?: boolean;
  isAncestorGrant: boolean;
  isProviderManaged: boolean;
  /** The resource (e.g. GCP project) in which this grant is provisioned */
  parent: string;
  permissionSet: string;
  principal: string;
  principalType: IdentityType;
  /** The service provider in which the grant is provisioned */
  provider: Provider;
  /** Grant resource patterns
   *
   * In AWS, this can be any arn pattern.
   * In GCP, this is always a length 1 explicit grant name.
   */
  resources: string[];
};

export type Usage = (typeof Usages)[number];

const LateralTypes = ["grant", "resource"] as const;
/** grant - Lateral movement via direct permissions
 *  resource - Lateral movement via a service identity
 */
export type LateralType = (typeof LateralTypes)[number];
export type Lateral = {
  type: LateralType;
  /** The identity to which movement is enabled */
  identity: string;
  /** The abused resource that allows movement; in the case of direct movement this is the policy
   *  that yields the necessary priviliges
   */
  resource: string;
  /** The resource's service */
  service: string;
};

export type AssessmentNodes = {
  grant: Grant;
  condition: GrantCondition | undefined;
  credential: Credential;
  consumer: CredentialConsumer;
  lateral: Lateral;
  permission: object;
  permissionSet: object;
  usage: { type: Usage; count?: number };
  identity: Identity;
  resource: Resource;
  risk: { name: string; score: RiskScore };
};

export const AssessmentNodeLabels: Readonly<
  Record<keyof AssessmentNodes, string>
> = {
  grant: "grants",
  resource: "resources",
  condition: "grant conditions",
  credential: "credentials",
  consumer: "credential consumers",
  lateral: "lateral movement paths",
  permission: "permissions",
  permissionSet: "permission sets",
  usage: "permission usage",
  identity: "identities",
  risk: "risks",
};
