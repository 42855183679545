import { Descriptions, Grid } from "antd";

export const NodeDescriptions: React.FC<
  React.PropsWithChildren<{ theme?: "normal" | "tight" }>
> = ({ children, theme }) => {
  const { md } = Grid.useBreakpoint();
  const padding = theme === "tight" ? "8px" : "12px";
  const fontSize = theme === "tight" ? "small" : undefined;

  return (
    <Descriptions
      bordered
      column={1}
      contentStyle={{
        // Note that antd adds extra padding when layout is vertical :(
        fontSize,
        padding: md ? padding : "0px",
        overflowX: "clip",
      }}
      labelStyle={{
        fontSize,
        fontWeight: 700,
        // See comment on 'contentStyle'
        padding: md ? padding : "0px",
        // Fits "Is provider managed ⓘ"
        width: md ? "165px" : undefined,
      }}
      layout={md ? "horizontal" : "vertical"}
      size="small"
    >
      {children}
    </Descriptions>
  );
};
