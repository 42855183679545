import { identityPredicate } from "shared/assessment/issues/presets";
import { identityReducers } from "shared/assessment/reducers/identity";

import { identityColumns } from "./columns";

export const identityData = {
  predicate: identityPredicate,
  reducers: identityReducers,
  columns: identityColumns,
};
